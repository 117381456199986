import React, { Component, useState } from 'react';
import { DropzoneArea, DropzoneAreaBase } from 'material-ui-dropzone';
import { DropzoneDialog } from 'material-ui-dropzone';
import './MakeNewRecipe.scss';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { spacing } from '@material-ui/system';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { sizing } from '@material-ui/system';
import { PagesSharp } from '@material-ui/icons';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Sidebar from '../../components/Sidebar';

function MakeNewRecipeStep1({
    MakeRecipeStep,
    setMakeRecipeStep,
    viewMakeNewRecipe,
    setViewMakeNewRecipe,
}) {
    const [file, setfile] = useState({
        files: [],
    });

    function handleChange(files) {
        setfile({
            files,
        });
    }

    return (
        <div className="scrollIt">
            <div className="newRecipe ikLazyCss">
                <h2>新しいレシピを作る</h2>
                <div className="wrap">
                    <ol className="stepBar">
                        <li className="visited">
                            <span>1</span>
                            <br></br>Step1
                        </li>
                        <li>
                            <span>2</span>
                            <br></br>Step2
                        </li>
                        <li>
                            <span>3</span>
                            <br></br>Step3
                        </li>
                        <li>
                            <span>4</span>
                            <br></br>Step4
                        </li>
                    </ol>

                    <form>
                        <h3>写真</h3>
                        <Box
                            className="dropzoneClass"
                            display="block"
                            mx="auto"
                        >
                            <DropzoneAreaBase
                                acceptedFiles={['image/*']}
                                onChange={(dropedfile) => {
                                    console.log(dropedfile);
                                }}
                                dropzoneText="ここに写真をドロップ　またはクリックして写真を選択"
                            />
                        </Box>

                        <h3>料理名</h3>
                        <TextField
                            fullWidth
                            size="small"
                            id="filled-basic"
                            label="料理名を入力してください"
                            variant="filled"
                        />

                        <h3>所要時間</h3>
                        <TextField
                            size="small"
                            type="number"
                            id="filled-basic"
                            label="MM"
                            variant="filled"
                        />

                        <h3>何人分</h3>
                        <ButtonGroup
                            size="large"
                            color="primary"
                            aria-label="outlined primary button group"
                        >
                            <Button>One</Button>
                            <Button>Two</Button>
                            <Button>Three</Button>
                        </ButtonGroup>

                        <br />

                        <Box display="block" mx="auto" my={7} width="350px">
                            <Box display="inline-block">
                                <Button
                                    variant="outlined"
                                    size="large"
                                    onClick={() =>
                                        setViewMakeNewRecipe(!viewMakeNewRecipe)
                                    }
                                >
                                    もどる
                                </Button>
                            </Box>
                            <Box display="inline-block" ml={20}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={() => setMakeRecipeStep(2)}
                                >
                                    つぎへ
                                </Button>
                            </Box>
                        </Box>
                    </form>
                </div>
            </div>
        </div>
    );
}

function MakeNewRecipeStep2({ setMakeRecipeStep }) {
    return (
        <div className="scrollIt">
            <div className="newRecipe ikLazyCss">
                <h2>新しいレシピを作る</h2>
                <div className="wrap">
                    <ol className="stepBar">
                        <li className="visited">
                            <span>1</span>
                            <br></br>Step1
                        </li>
                        <li className="visited">
                            <span>2</span>
                            <br></br>Step2
                        </li>
                        <li>
                            <span>3</span>
                            <br></br>Step3
                        </li>
                        <li>
                            <span>4</span>
                            <br></br>Step4
                        </li>
                    </ol>

                    <form>
                        <h3>材料</h3>

                        <h3>キーワードで検索</h3>

                        <Box display="block" mx="auto" my={7} width="350px">
                            <Box display="inline-block">
                                <Button
                                    variant="outlined"
                                    size="large"
                                    onClick={() => setMakeRecipeStep(1)}
                                >
                                    もどる
                                </Button>
                            </Box>
                            <Box display="inline-block" ml={20}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={() => setMakeRecipeStep(3)}
                                >
                                    つぎへ
                                </Button>
                            </Box>
                        </Box>
                    </form>
                </div>
            </div>
        </div>
    );
}

function MakeNewRecipeStep3({
    setMakeRecipeStep,
    viewMakeNewRecipe,
    setViewMakeNewRecipe,
}) {
    return (
        <div className="scrollIt">
            <div className="newRecipe ikLazyCss">
                <h2>新しいレシピを作る</h2>
                <div className="wrap">
                    <ol className="stepBar">
                        <li className="visited">
                            <span>1</span>
                            <br></br>Step1
                        </li>
                        <li className="visited">
                            <span>2</span>
                            <br></br>Step2
                        </li>
                        <li className="visited">
                            <span>3</span>
                            <br></br>Step3
                        </li>
                        <li>
                            <span>4</span>
                            <br></br>Step4
                        </li>
                    </ol>

                    <form>
                        <h3>レシピ</h3>
                        <h4>※最低でも3つは手順を入力してください</h4>
                        <p>1.</p>
                        <TextField
                            fullWidth
                            size="small"
                            id="filled-basic"
                            label="手順を入力してください"
                            variant="filled"
                        />
                        <p>2.</p>
                        <TextField
                            fullWidth
                            size="small"
                            id="filled-basic"
                            label="手順を入力してください"
                            variant="filled"
                        />
                        <p>3.</p>
                        <TextField
                            fullWidth
                            size="small"
                            id="filled-basic"
                            label="手順を入力してください"
                            variant="filled"
                        />

                        <Box className="plus" display="block" width="80px">
                            <IconButton>
                                <AddCircleOutlineIcon
                                    fontSize="large"
                                    color="secondary"
                                    mx="auto"
                                />
                            </IconButton>
                        </Box>

                        <Box display="block" mx="auto" my={7} width="350px">
                            <Box display="inline-block">
                                <Button
                                    variant="outlined"
                                    size="large"
                                    onClick={() => setMakeRecipeStep(2)}
                                >
                                    もどる
                                </Button>
                            </Box>
                            <Box display="inline-block" ml={20}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={() => setMakeRecipeStep(4)}
                                >
                                    つぎへ
                                </Button>
                            </Box>
                        </Box>
                    </form>
                </div>
            </div>
        </div>
    );
}

function MakeNewRecipeStep4({
    setMakeRecipeStep,
    viewMakeNewRecipe,
    setViewMakeNewRecipe,
}) {
    return (
        <div className="scrollIt">
            <div className="newRecipe ikLazyCss">
                <h2>新しいレシピを作る</h2>
                <div className="wrap">
                    <ol className="stepBar">
                        <li className="visited">
                            <span>1</span>
                            <br></br>Step1
                        </li>
                        <li className="visited">
                            <span>2</span>
                            <br></br>Step2
                        </li>
                        <li className="visited">
                            <span>3</span>
                            <br></br>Step3
                        </li>
                        <li className="visited">
                            <span>4</span>
                            <br></br>Step4
                        </li>
                    </ol>
                    <form>
                        <h3>紹介文</h3>
                        <TextField
                            fullWidth
                            size="small"
                            id="filled-basic"
                            multiline
                            rows={5}
                            label="紹介文を入力してください（200字以内）"
                            variant="filled"
                        />

                        <h3>タグ</h3>
                        <Box width={200} mx="auto" mt={7}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={() =>
                                    setViewMakeNewRecipe(!viewMakeNewRecipe)
                                }
                            >
                                トップに戻る
                            </Button>
                        </Box>
                    </form>
                </div>
            </div>
        </div>
    );
}

function MakeNewRecipe() {
    const [MakeRecipeStep, setMakeRecipeStep] = useState(1);
    const [viewMakeNewRecipe, setViewMakeNewRecipe] = useState(true);

    switch (MakeRecipeStep) {
        case 1:
            return (
                <MakeNewRecipeStep1
                    MakeRecipeStep={MakeRecipeStep}
                    setMakeRecipeStep={setMakeRecipeStep}
                    viewMakeNewRecipe={viewMakeNewRecipe}
                    setViewMakeNewRecipe={setViewMakeNewRecipe}
                />
            );

        case 2:
            return (
                <MakeNewRecipeStep2
                    MakeRecipeStep={MakeRecipeStep}
                    setMakeRecipeStep={setMakeRecipeStep}
                />
            );

        case 3:
            return (
                <MakeNewRecipeStep3
                    MakeRecipeStep={MakeRecipeStep}
                    setMakeRecipeStep={setMakeRecipeStep}
                    viewMakeNewRecipe={viewMakeNewRecipe}
                    setViewMakeNewRecipe={setViewMakeNewRecipe}
                />
            );

        case 4:
            return (
                <MakeNewRecipeStep4
                    MakeRecipeStep={MakeRecipeStep}
                    setMakeRecipeStep={setMakeRecipeStep}
                    viewMakeNewRecipe={viewMakeNewRecipe}
                    setViewMakeNewRecipe={setViewMakeNewRecipe}
                />
            );

        default:
        // return <></>
    }
}

export default MakeNewRecipe;
