/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import './Movie.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { spacing, sizing } from '@material-ui/system';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { IconButton } from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { getElementError } from '@testing-library/react';
import Material from './material';
import Mov from '../images/mov/nikujaga.gif';
import Load from '../images/load.gif';

function Movie({ stepState, setStepState }) {
    const { activeStep, lastStepCount, allStep } = stepState;
    const handleNext = () => {
        if (activeStep < lastStepCount) {
            setStepState((prev) => ({
                ...prev,
                activeStep: activeStep + 1,
            }));
        } else if (activeStep === lastStepCount) {
            setStepState((prev) => ({
                ...prev,
                complete: true,
            }));
        }
    };

    const handleBack = () => {
        if (activeStep > 1) {
            setStepState((prev) => ({
                ...prev,
                activeStep: activeStep - 1,
                complete: false,
            }));
        }
    };

    return (
        <div className="movie">
            <div className="wrapper">
                <div className="boxi1">
                    <h3>STEP {allStep ? activeStep : 'Loading...'}</h3>
                </div>
                <div
                    className="boxi3"
                    id="render_movie"
                    style={{
                        backgroundImage: `url(${
                            allStep ? allStep[activeStep]?.url : Load
                        })`,
                    }}
                ></div>
                <div className="boxi4">
                    <ButtonGroup
                        variant="text"
                        color="primary"
                        aria-label="text primary button group"
                        fullWidth="true"
                    >
                        <Button onClick={handleBack}>
                            <ArrowBackIcon fontSize="large" />
                        </Button>
                        <Button onClick={handleNext}>
                            <ArrowForwardIcon fontSize="large" />
                        </Button>
                    </ButtonGroup>
                </div>
                <div className="boxi2">
                    <Material></Material>
                </div>
            </div>
        </div>
    );
}

export default Movie;
