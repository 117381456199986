/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import './Share.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { spacing } from '@material-ui/system';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { sizing } from '@material-ui/system';
import Dish from '../images/dish1.jpg';
import Dish2 from '../images/dish2.jpg';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InstagramIcon from '../images/icons/instagram.gif';
import TwitterIcon from '../images/icons/twitter.gif';
import FacebookIcon from '../images/icons/facebook.gif';
import LineIcon from '../images/icons/line.gif';
import { useLocation } from 'react-router-dom';

function Share() {
    const location = useLocation();
    const { href } = window.location;
    const encodedHref = encodeURI(href);
    function shareOnFB() {
        const url = `https://www.facebook.com/sharer/sharer.php?u=${href}&t=CHECK OUT THIS SITE `;
        window.open(
            url,
            '',
            'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600',
        );
        return false;
    }

    function shareOnTwitter() {
        const url = `https://twitter.com/intent/tweet?url=${href}&via=WEIJIE27&text=Check out `;
        window.open(url, 'TwitterWindow', `width=600,height=300`);
        return false;
    }

    function shareOnLine() {
        const url = `https://line.me/R/msg/text/?${encodedHref}`;
        window.open(url, 'LineWindow', `width=600,height=700`);
        return false;
    }

    return (
        <div className="shareit">
            <Button onClick={shareOnTwitter}>
                <img src={TwitterIcon} />
            </Button>
            <Button>
                <img src={InstagramIcon} />
            </Button>
            <Button onClick={shareOnFB}>
                <img src={FacebookIcon} />
            </Button>
            <Button onClick={shareOnLine}>
                <img src={LineIcon} />
            </Button>
        </div>
    );
}

export default Share;
