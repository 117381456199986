import { useLocation } from 'react-router-dom';
import { combineReducers } from 'redux';
// GLOBAL APP STATUS REDUCER

const { pathname, search, hash } = window.location;
const initialState = {
    route: pathname + search + hash,
    currentUser: null,
};

export const globalReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case 'UPDATE_AUTH':
            return { ...state, ...payload };
        case 'UPDATE_ROUTE':
            return { ...state, ...payload };
        // case 'LOG_IN':
        //     return { ...state, isLoggedIn: true, ...payload };
        // case 'LOG_OUT':
        //     return { ...state, isLoggedIn: false, ...payload };
        default:
            return state;
    }
};

// search

const initialStateForSearch = {
    allTags: {},
};

export const searchReducer = (
    state = initialStateForSearch,
    { type, payload },
) => {
    switch (type) {
        case 'SAVE_ALL_TAGS':
            return { ...state, ...payload };
        case 'SAVE_LAST_GROUP_NUM':
            return { ...state, ...payload };
        default:
            return state;
    }
};
// SEARCH RESULTS STATUS REDUCER
const initialStateForSearchResults = {};

export const searchResultsReducer = (
    state = initialStateForSearch,
    { type, payload },
) => {
    switch (type) {
        case 'FETCH':
            return { ...state, ...payload };
        default:
            return state;
    }
};

// COMBINE MULTIPLE REDUCERS
const rootReducer = combineReducers({
    global: globalReducer,
    search: searchReducer,
    searchResults: searchResultsReducer,
});

export default rootReducer;
