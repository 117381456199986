import React, { useState } from 'react';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import './SignUp.scss';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { spacing } from '@material-ui/system';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { sizing } from '@material-ui/system';
import { PagesSharp } from '@material-ui/icons';
import { auth } from '../../firebase';
import firebase from 'firebase';
import { useSelector } from 'react-redux';

function SignUpStep1({ history, setSignUpState }) {
    const [loading, setLoading] = useState(false);
    const submitHandler = async (e) => {
        e.preventDefault();
        console.log(e.target);
        const form = e.target;
        const email = form.querySelector("input[id='email']").value;
        const password = form.querySelector("input[id='password']").value;
        const userName = form.querySelector("input[id='user-name']").value;
        const userId = form.querySelector("input[id='user-id']").value;
        try {
            setLoading(true);
            const currentUser = await auth.createUserWithEmailAndPassword(
                email,
                password,
            );
            const { uid } = currentUser.user;
            const usertblRef = firebase.database().ref('USER_TBL').child(email);
            await usertblRef.update({
                uid,
                email,
                creatTime: new Date().getTime(),
                userName,
                userId,
            });
            setSignUpState(2);
            history.push('/signup/step2');
        } catch (err) {
            console.log(err);
            alert(err.message);
            setLoading(false);
        }
    };

    const theme = {
        spacing: 8,
    };
    return (
        <div className="SignUp ikLazyCss">
            <h2>新規登録</h2>
            {/* レシピのグリッド仮入れ */}

            <div className="wrap">
                <ol className="stepBar">
                    <li className="visited">
                        <span>1</span>
                        <br></br>入力
                    </li>
                    <li>
                        <span>2</span>
                        <br></br>確認
                    </li>
                    <li>
                        <span>3</span>
                        <br></br>完了
                    </li>
                </ol>

                <form onSubmit={submitHandler} method="post">
                    <h3>ユーザーID</h3>
                    <TextField
                        fullWidth
                        size="small"
                        className="filled-basic"
                        label="ユーザーIDを入力してください"
                        variant="filled"
                        id="user-id"
                    />

                    <h3>パスワード</h3>
                    <h4>
                        ※ 半角英数字8~12文字で、英語と数字を組み合わせてください
                    </h4>
                    <TextField
                        fullWidth
                        size="small"
                        type="password"
                        className="filled-basic"
                        label="パスワードを入力してください"
                        variant="filled"
                        id="password"
                    />

                    <h3>メールアドレス</h3>
                    <TextField
                        fullWidth
                        size="small"
                        className="filled-basic"
                        label="メールアドレスを入力してください"
                        variant="filled"
                        id="email"
                    />

                    <h3>ユーザー名</h3>
                    <TextField
                        fullWidth
                        size="small"
                        className="filled-basic"
                        label="ユーザー名を入力してください"
                        variant="filled"
                        id="user-name"
                    />

                    <br />

                    <Box display="block" mx="auto" my={7} width="350px">
                        <Box display="inline-block">
                            <Button
                                variant="outlined"
                                size="large"
                                onClick={() => {
                                    history.push('/login');
                                }}
                            >
                                もどる
                            </Button>
                        </Box>
                        <Box display="inline-block" ml={20}>
                            <Button
                                disabled={loading}
                                variant="contained"
                                color="primary"
                                size="large"
                                className="next"
                                type="submit"
                            >
                                つぎへ
                            </Button>
                        </Box>
                    </Box>
                </form>
            </div>
        </div>
    );
}

function SignUpStep2({ setSignUpState, history }) {
    const currentUser = useSelector((state) => state.global.currentUser);
    const submitHandler = async (e) => {
        e.preventDefault();
        console.log(e.target);
        const form = e.target;
        const name = form.querySelector("input[id='name']").value;
        const cardNumber = form.querySelector("input[id='card-number']").value;
        const year = form.querySelector("input[id='year']").value;
        const month = form.querySelector("input[id='month']").value;
        try {
            console.log(currentUser);
            const { uid } = currentUser;
            const usertblRef = firebase.database().ref('CREDIT_TBL').child(uid);
            await usertblRef.update({
                uid,
                name,
                creat_time: new Date().getTime(),
                credit_num: cardNumber,
                year,
                month,
            });
            currentUser.sendEmailVerification();
            setSignUpState(3);
            history.push('/signup/step3');
        } catch (err) {
            console.log(err);
            alert(err.message);
        }
    };

    return (
        <div className="SignUp ikLazyCss">
            <h2>新規登録</h2>

            <div className="wrap">
                <ol className="stepBar">
                    <li className="visited">
                        <span>1</span>
                        <br></br>入力
                    </li>
                    <li className="visited">
                        <span>2</span>
                        <br></br>確認
                    </li>
                    <li>
                        <span>3</span>
                        <br></br>完了
                    </li>
                </ol>

                <form onSubmit={submitHandler}>
                    <h3>クレジットカードを登録する</h3>
                    <h4>
                        登録から7日間は無料ですべての機能をお使い頂けます。
                        <br></br>
                        7日後の更新日までに解約が行われなかった場合、ご請求が発生します。
                    </h4>

                    <h3>カード名義人</h3>
                    <h4>※ 半角英文字で入力してください</h4>
                    <TextField
                        id="name"
                        fullWidth
                        size="small"
                        type="text"
                        className="filled-basic"
                        label="カード名義人を入力"
                        variant="filled"
                    />

                    <h3>カード番号</h3>
                    <h4>※ 半角で入力してください</h4>
                    <TextField
                        id="card-number"
                        fullWidth
                        type="number"
                        size="small"
                        className="filled-basic"
                        label="カード番号を入力"
                        variant="filled"
                    />

                    <h3>有効期限（月/年）</h3>
                    <Box width={70} display="inline-block">
                        <TextField
                            id="month"
                            size="small"
                            type="number"
                            className="filled-basic"
                            label="MM"
                            variant="filled"
                        />
                    </Box>

                    <Box width={70} ml={1} display="inline-block">
                        <TextField
                            id="year"
                            size="small"
                            type="number"
                            className="filled-basic"
                            label="YY"
                            variant="filled"
                        />
                    </Box>

                    <br />

                    <Box display="block" mx="auto" my={7} width="350px">
                        <Box display="inline-block">
                            <Button
                                variant="outlined"
                                size="large"
                                onClick={() => {
                                    setSignUpState(1);
                                    history.push('/signup/step3');
                                }}
                            >
                                いいわ
                            </Button>
                        </Box>
                        <Box display="inline-block" ml={20}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                type="submit"
                            >
                                つぎへ
                            </Button>
                        </Box>
                    </Box>
                </form>
            </div>
        </div>
    );
}

function SignUpStep3({ history, setSignUpState }) {
    return (
        <div className="SignUp ikLazyCss">
            <h2>新規登録</h2>

            <div className="wrap">
                <ol className="stepBar">
                    <li className="visited">
                        <span>1</span>
                        <br></br>入力
                    </li>
                    <li className="visited">
                        <span>2</span>
                        <br></br>確認
                    </li>
                    <li className="visited">
                        <span>3</span>
                        <br></br>完了
                    </li>
                </ol>

                <h3>
                    新規登録が完了しました。<br></br>
                    お客様のメールアドレスに確認のメールを<br></br>
                    お送り致しましたのでご確認ください。
                </h3>

                <Box width={200} mx="auto" mt={7}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => {
                            history.push('/login');
                        }}
                    >
                        トップに戻る
                    </Button>
                </Box>
            </div>
        </div>
    );
}

function SignUp() {
    const [signUpState, setSignUpState] = useState(1);

    return (
        <Switch>
            <Route
                exact
                path="/signup"
                render={(props) => (
                    <SignUpStep1 {...props} {...{ setSignUpState }} />
                )}
            />
            <Route
                exact
                path="/signup/step1"
                render={(props) => (
                    <SignUpStep1 {...props} {...{ setSignUpState }} />
                )}
            />
            <Route
                exact
                path="/signup/step2"
                render={(props) => (
                    <SignUpStep2 {...props} {...{ setSignUpState }} />
                )}
            />
            <Route
                exact
                path="/signup/step3"
                render={(props) => (
                    <SignUpStep3 {...props} {...{ setSignUpState }} />
                )}
            />
        </Switch>
    );
}

export default SignUp;
