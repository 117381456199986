/* eslint-disable import/prefer-default-export */
import firebase from 'firebase';
import 'firebase/auth';

const config = {
    apiKey: 'AIzaSyAn5Z9sPqOQ6-vMW7n5X6jsKSEonCP7j6A',
    authDomain: 'ikkeitest.firebaseapp.com',
    databaseURL: 'https://ikkeitest.firebaseio.com',
    projectId: 'ikkeitest',
    storageBucket: 'ikkeitest.appspot.com',
    messagingSenderId: '990616044747',
    appId: '1:990616044747:web:20da374c59c6eed70a074e',
    measurementId: 'G-SPKM21CJQZ',
};
export const firebaseApp = firebase.initializeApp(config);
export const auth = firebaseApp.auth();
export const firestore = firebaseApp.firestore();
