import React from 'react';
import './PayInfoChange.css';
import { DropzoneArea, DropzoneAreaBase } from 'material-ui-dropzone';
import { DropzoneDialog } from 'material-ui-dropzone';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { spacing } from '@material-ui/system';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { sizing } from '@material-ui/system';
import { PagesSharp } from '@material-ui/icons';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

function PayInfoChange({ history }) {
    return (
        <div className="payInfoChange ikLazyCss">
            <h2>お支払情報変更</h2>
            <div className="wrap">
                <form>
                    <h3>カード名義人</h3>
                    <h4>※ 半角英文字で入力してください</h4>
                    <TextField
                        fullWidth
                        size="small"
                        type="text"
                        id="filled-basic"
                        label="カード名義人を入力"
                        variant="filled"
                    />

                    <h3>カード番号</h3>
                    <h4>※ 半角で入力してください</h4>
                    <TextField
                        fullWidth
                        type="number"
                        size="small"
                        id="filled-basic"
                        label="カード番号を入力"
                        variant="filled"
                    />

                    <h3>有効期限（月/年）</h3>
                    <Box width={70} display="inline-block">
                        <TextField
                            size="small"
                            type="number"
                            id="filled-basic"
                            label="MM"
                            variant="filled"
                        />
                    </Box>

                    <Box width={70} ml={1} display="inline-block">
                        <TextField
                            size="small"
                            type="number"
                            id="filled-basic"
                            label="YY"
                            variant="filled"
                        />
                    </Box>

                    <Box display="block" mx="auto" my={7} width="350px">
                        <Box display="inline-block">
                            <Button
                                variant="outlined"
                                size="large"
                                onClick={() => {
                                    history.push('/mypage');
                                }}
                            >
                                もどる
                            </Button>
                        </Box>
                        <Box display="inline-block" ml={20}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                            >
                                変更
                            </Button>
                        </Box>
                    </Box>
                </form>
            </div>
        </div>
    );
}

export default PayInfoChange;
