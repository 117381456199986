import React, { useState } from 'react';
import './RecipeGridv2.scss';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { spacing } from '@material-ui/system';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { sizing } from '@material-ui/system';
import Dish from '../images/dish1.jpg';
import Dish2 from '../images/dish2.jpg';
import Time from '../images/icons/time_15.gif';
import Money from '../images/icons/money.gif';
import Pro from '../images/icons/pro.gif';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Fab from '@material-ui/core/Fab';
import Normal from '../images/icons/normal.gif';
import Difficult from '../images/icons/difficult.gif';
import Master from '../images/icons/master.gif';
import Easy from '../images/icons/easy.gif';
import Load from '../images/load.gif';
import firebase from 'firebase';
import { useLayoutEffect } from 'react';
import { useEffect } from 'react';

function Recipe({ recipeId }) {
    const difficultyImg = [null, Easy, Normal, Difficult, Master];
    const difficultyText = [null, 'Easy', 'Normal', 'Difficult', 'Master'];
    const [state, setState] = useState({});
    const [tags, setTags] = useState({});
    const [bookmark, setBookmark] = useState(0);
    const {
        id,
        recipe_name,
        difficulty = 1,
        image,
        markCount,
        reviewCount,
        pro,
        time,
        money,
        setumei,
        nu_carbon,
        nu_energy,
        nu_fat,
        nu_fiber,
        nu_iron,
        nu_mineral,
        nu_potassium,
        nu_protein,
        nu_sodium,
        nu_vitamin,
    } = state;

    useEffect(() => {
        // console.log('recipe grid get id : ', typeof recipeId, recipeId);
        if (!Number.isNaN(recipeId)) {
            const tableRef = firebase.database().ref('RECIPE_TBL');
            const recipeIdRef = tableRef.orderByChild('id').equalTo(recipeId);
            recipeIdRef.once('value', (snapshot) => {
                const val = snapshot.val();
                // console.log(val);
                if (val) {
                    const row = Object.values(val)[0];
                    setState({
                        ...state,
                        ...row,
                    });
                }
            });
        }
        return () => {};
    }, []);
    // BOOKMARK
    useEffect(() => {
        const zairyoTableRef = firebase.database().ref('COOKBOOK_TBL');
        const tagRef = zairyoTableRef
            .orderByChild('recipe_id')
            .equalTo(recipeId);
        tagRef.once('value', (snapshot) => {
            const rows = snapshot.val();
            // console.log('recipe grid bookmark rows', rows);
            if (rows) {
                const count = Object.values(rows)?.length;
                setBookmark(count);
            }
        });
        return () => {};
    }, []);

    // ZAIRYO TAG
    useEffect(() => {
        const zairyoTableRef = firebase.database().ref('RECIPE_ZAIRYO_TBL');
        const tagRef = zairyoTableRef.orderByChild('id').equalTo(recipeId);
        tagRef.once('value', (snapshot) => {
            const rows = snapshot.val();
            // console.log('recipe grid tags rows', rows);
            setTags((prev) => ({
                ...prev,
                ...rows,
            }));
        });
        return () => {};
    }, []);

    return (
        <div className="recipe_card">
            <Box width="350px">
                <h2>{recipe_name}</h2>
                {image ? (
                    <img alt="DISH" src={image} className="dish" />
                ) : (
                    <img src={Load} className="dish" alt="DISH" />
                )}

                <div className="bukuma">ブックマーク数：{bookmark}</div>

                <Box display="block" ml="10px" width="340px" my={1}>
                    <p className="cardinfo">{setumei}</p>
                </Box>

                <Box
                    display="inline-block"
                    width="150px"
                    className="cardiconmaster"
                >
                    <div
                        className={`cardicon ${
                            difficulty &&
                            difficultyText[difficulty].toLowerCase()
                        }`}
                    >
                        <img src={difficultyImg[difficulty]} alt="" />
                        <p>{difficultyText[difficulty]}</p>
                    </div>
                    <div className="cardicon min15">
                        <img src={Time} alt="" />
                        <p>{`${time || '0'}min`}</p>
                    </div>
                    <div className="cardicon money">
                        <img src={Money} alt="" />
                        <p>{money}</p>
                    </div>
                    {pro && (
                        <div className="cardicon pro">
                            <img src={Pro} alt="" />
                            <p>Pro</p>
                        </div>
                    )}
                </Box>

                <Box
                    display="inline-block"
                    width="200px"
                    className="cardseibun"
                >
                    {/* <h5>主な成分</h5> */}

                    <TableContainer>
                        <Table size="small" aria-label="a dense table">
                            <TableBody>
                                <TableRow>
                                    <TableCell>エネルギー</TableCell>
                                    <TableCell>{nu_energy}kcal</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>タンパク質</TableCell>
                                    <TableCell>{nu_protein}mg</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>炭水化物</TableCell>
                                    <TableCell>{nu_carbon}mg</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>脂質</TableCell>
                                    <TableCell>{nu_fat}mg</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>食物繊維</TableCell>
                                    <TableCell>{nu_fiber}mg</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>ナトリウム</TableCell>
                                    <TableCell>{nu_sodium}mg</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>ヴィタミン</TableCell>
                                    <TableCell>{nu_vitamin}mg</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <hr />
                <Box className="cardtag" mx={1} my={1}>
                    {/* <Box display="inline-block" mx={0.5} my={0.2}>
                        #トマト
                    </Box> */}
                    {Object.values(tags).length > 0 &&
                        Object.values(tags).map((item) => (
                            <Box display="inline-block" mx={0.5} my={0.2}>
                                {`#${item.zairyo}`}
                            </Box>
                        ))}
                </Box>
            </Box>
        </div>
    );
}

export default Recipe;
