import React, { useState } from 'react';
import './Intro.css';
import { Button } from '@material-ui/core';
import SignUp from './SignUp';
import { Link } from 'react-router-dom';
import topLogo from '../../images/title.png';
import chef from '../../images/chef.png';
import sample1 from '../../images/sample1.png';
import sample2 from '../../images/sample2.png';
import foods from '../../images/foods.jpg';
import fb from '../../images/fitbit.jpg';
import LogIn from './LogIn';

const styles = {
    fontSize: '1rem',
    margin: '4rem 0',
};

function Intro() {
    // デフォルトでログインと新規登録を表示
    const [isLogin, setIsLogin] = React.useState(false);
    return (
        <div className="Intro">
            {isLogin && <LogIn setIsLogin={setIsLogin} />}
            <div id="sec1" className="sections">
                <div className="nav">
                    <img
                        src={topLogo}
                        alt="ロゴ"
                        id="toplogo"
                        className="fade-in-top"
                    ></img>
                    <div className="LogIn">
                        <span>
                            {/* <Link to="/login">login</Link> */}
                            <Button
                                onClick={() => {
                                    setIsLogin((prev) => !prev);
                                }}
                            >
                                login
                            </Button>
                        </span>
                        <span> / </span>
                        <span>
                            <Link to="/signup">signup</Link>
                        </span>
                    </div>
                </div>
                <p className="catchCopy">１万を超えるレシピで</p>
                <p className="catchCopy">今日からあなたも三ツ星シェフ</p>
                <img src={chef} alt="シェフ" id="chefimg"></img>
                <div className="gif"></div>
            </div>

            <div className="interval"></div>

            <div id="sec2" className="sections">
                <div id="slideR">
                    <div className="sideR">
                        <h1>レシピ</h1>
                        <p className="desc">人気レシピは分かりやすい動画付き</p>
                        <p className="desc">人気レシピは分かりやすい動画付き</p>
                        <p className="desc">人気レシピは分かりやすい動画付き</p>
                        <p className="desc">人気レシピは分かりやすい動画付き</p>
                    </div>
                </div>
                <img src={sample1} alt="サンプル画像1" id="sam1"></img>
                <img src={sample2} alt="サンプル画像2" id="sam2"></img>
            </div>

            {/* <div className="interval"></div> */}

            <div id="slideShow">
                <div className="loop_wrap">
                    <img src={foods} alt="料理の写真"></img>
                    <img src={foods} alt="料理の写真"></img>
                </div>
            </div>

            <div id="sec3" className="sections">
                <div id="slideL">
                    <div className="sideL">
                        <h1>ヘルスケア</h1>
                        <p className="desc">
                            ヘルスケアAIがあなたの食生活をサポート
                        </p>
                        <p className="desc">人気レシピは分かりやすい動画付き</p>
                        <p className="desc">人気レシピは分かりやすい動画付き</p>
                        <p className="desc">
                            FitBitとの連携でどこでもWill Chef
                        </p>
                    </div>
                </div>
                <img src={fb} alt="FitBit" id="fb"></img>
            </div>
        </div>
    );
}

export default Intro;
