import React, { useEffect, useState } from 'react';
import Material from '../../components/material';
import Share from '../../components/Share';
import Memo from '../../components/Memo';
import Movie from '../../components/Movie';
import Timer from '../../components/Timer';
import RecipeStep from '../../components/Step';
import Review from '../../components/Review';
import Add from '../../components/Add';
import Drop from '../../components/Drop';
import Download from '../../components/Download';
import './Recipe.css';

import back from '../../images/icons/BACK_white.gif';
import loveNo from '../../images/icons/add_book.gif';
import loveYes from '../../images/icons/love_yes.gif';
import download from '../../images/icons/download_b.gif';
import calc from '../../images/icons/calc.gif';
import memo from '../../images/icons/memo.gif';
import movie from '../../images/icons/movie.gif';
import share from '../../images/icons/share.gif';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';

import RecipeGridv2 from '../../components/RecipeGridv2';
import { Box, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import firebase from 'firebase';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

function Recipe() {
    const history = useHistory();
    const location = useLocation();
    const param = useParams();

    const recipeId = parseInt(param.id, 10);
    if (Number.isNaN(recipeId)) {
        history.push('/');
    }
    const currentUser = useSelector((state) => state.global.currentUser);
    const [isLove, setIsLove] = useState(false);
    const [box2, setbox2] = useState('RecipeGridv2');
    const [activeStep, setActiveStep] = useState(1);
    const [stepState, setStepState] = useState({});

    // GET STEP
    useEffect(() => {
        const ref = firebase.database().ref(`RECIPE_STEP_TBL`);
        const recipeStepRef = ref.orderByChild('id').equalTo(recipeId);
        recipeStepRef.once('value', (snapshot) => {
            // console.log('recipe step table : ', snapshot.val());
            // 2_1: {id: 2, renban: 1, text: "鶏むね肉を包丁で叩き、エリンギは細かいみじん切りに、パンの耳は包丁で5ミリ角に切る。", url: "https://firebasestorage.googleapis.com/v0/b/ikkeit…=media&token=ea45e871-7eef-451f-8e9f-e07a2c651779"}
            // 2_2: {id: 2, renban: 2, text: "ボウルに鶏むね肉を入れ、塩コショウをして粘りが出るまでよく捏ね、エリンギ、生姜のすりおろし、片栗粉を入れてよく混ぜ合わせる。", url: "https://firebasestorage.googleapis.com/v0/b/ikkeit…=media&token=f6d5aabe-05c2-42b8-9ac6-c839c025f1b7"}
            // 2_3: {id: 2, renban: 3, text: "(2)を一口大の大きさに取り、カットしたスライスチーズ を中央に入れて包む。", url: "https://firebasestorage.googleapis.com/v0/b/ikkeit…=media&token=db65afe4-baef-412f-8271-d13a21ca82ee"}
            // 2_4: {id: 2, renban: 4, text: "(3)に薄力粉、溶き卵、パンの耳の順で衣をつけ、170度に熱した油
            const rowsObj = snapshot.val();

            // RENAME OBJECT ROW KEY
            const allStep = Object.fromEntries(
                Object.entries(rowsObj).map(([key, value]) => [
                    Number.parseInt(value.renban, 10),
                    value,
                ]),
            );

            // GET LAST STEP COUNT
            let lastStepCount = 1;
            Object.values(rowsObj).forEach((row) => {
                if (Number.parseInt(row.renban, 10) >= lastStepCount) {
                    lastStepCount = Number.parseInt(row.renban, 10);
                }
            });
            console.log('all step : ', allStep[1]);
            setStepState((prev) => ({
                ...prev,
                activeStep: 1,
                lastStepCount,
                allStep,
            }));
        });
        return () => {
            recipeStepRef?.off();
        };
    }, [recipeId]);

    // INITIAL LOVE BUTTON
    useEffect(() => {
        const ref = firebase.database().ref(`COOKBOOK_TBL`);
        let loveRef;
        if (currentUser) {
            loveRef = ref.child(recipeId + '_' + currentUser.uid);
            loveRef.once('value', (snapshot) => {
                console.log('love val', snapshot.val());
                const row = snapshot.val();
                if (row?.flag === 1) {
                    setIsLove(true);
                }
            });
        }
        return () => {
            loveRef?.off();
        };
    }, [currentUser]);

    // UPDATE RIREKI TO DATABASE
    useEffect(() => {
        let ref;
        if (currentUser) {
            const primaryKey = `${recipeId}_${currentUser.uid}`;
            const ref = firebase.database().ref('RIREKI_TBL').child(primaryKey);
            ref.update({
                recipe_id: recipeId,
                uid: currentUser.uid,
                update_time: new Date().getTime(),
                uid_recipe_id: primaryKey,
            });
        }
        return () => {
            ref?.off();
        };
    }, [currentUser]);

    const components = {
        RecipeGridv2,
        Timer,
        Memo,
        Movie,
        Share,
        Add,
        Drop,
        Download,
    };
    const Box2 = components[box2];

    function handleBox2Change(arg) {
        if (box2 !== arg) {
            setbox2(arg);
            if (arg == 'Add' || arg == 'Drop') {
                window.setTimeout(function () {
                    setbox2('RecipeGridv2');
                }, 2000);
            }
        } else {
            setbox2('RecipeGridv2');
        }
    }

    function changeLoveHandler(to) {
        const primaryKey = `${recipeId}_${currentUser.uid}`;
        const ref = firebase.database().ref('COOKBOOK_TBL').child(primaryKey);

        if (to === true) {
            ref.update({
                recipe_id: recipeId,
                uid: currentUser.uid,
                update_time: new Date().getTime(),
                flag: 1,
                uid_recipe_id: primaryKey,
            }).then(() => setIsLove((prev) => to));
        } else if (to === false) {
            // ref.update({
            //     recipe_id: recipeId,
            //     uid: currentUser.uid,
            //     update_time: new Date().getTime(),
            //     flag: 0,
            //     uid_recipe_id: primaryKey,
            // });
            // DELETE DATABASE ROW
            ref.remove().then(() => setIsLove((prev) => to));
        }
    }

    return (
        <div className="recipe">
            <Box className="wrap1" display="inline-block">
                <Box className="box1">
                    <Button
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        <img className="back" src={back} alt="" />
                    </Button>
                    {isLove ? (
                        <Button
                            onClick={() => {
                                handleBox2Change('Drop');
                                changeLoveHandler(false);
                            }}
                        >
                            <img src={loveYes} alt="" />
                        </Button>
                    ) : (
                        <Button
                            onClick={() => {
                                handleBox2Change('Add');
                                changeLoveHandler(true);
                            }}
                        >
                            <img src={loveNo} alt="" />
                        </Button>
                    )}
                    <Button
                        onClick={() => {
                            history.push(`/misc/print/?recipe_id=${recipeId}`);
                        }}
                    >
                        <img src={download} alt="" />
                    </Button>
                </Box>

                <Box className="box2">
                    <Box2
                        {...{
                            stepState,
                            setStepState,
                            recipeId,
                        }}
                    />
                </Box>

                <Box className="box3">
                    <Button onClick={() => handleBox2Change('Timer')}>
                        <img src={calc} alt="" />
                    </Button>
                    <Button onClick={() => handleBox2Change('Memo')}>
                        <img src={memo} alt="" />
                    </Button>
                    <Button onClick={() => handleBox2Change('Movie')}>
                        <img src={movie} alt="" />
                    </Button>
                    <Button onClick={() => handleBox2Change('Share')}>
                        <img src={share} alt="" />
                    </Button>
                </Box>
            </Box>

            <Box className="wrap2" display="inline-block">
                <Box className="box1">
                    <h3>材料</h3>

                    <Box className="cont1">
                        <Material></Material>{' '}
                        <Box className="material" display="inline-block">
                            <TableContainer>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <div className="border"></div>
                                                にんにく
                                            </TableCell>
                                            <TableCell align="right">
                                                ４片
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <div className="border"></div>
                                                ほうれん草
                                            </TableCell>
                                            <TableCell align="right">
                                                140g
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <div className="border"></div>
                                                牛乳
                                            </TableCell>
                                            <TableCell align="right">
                                                5カップ
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <div className="border"></div>
                                                フェットチーネパスタ
                                            </TableCell>
                                            <TableCell align="right">
                                                455g
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <div className="border"></div>
                                                バジルソース
                                            </TableCell>
                                            <TableCell align="right">
                                                110g
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <div className="border"></div>
                                                パルメザンチーズ
                                            </TableCell>
                                            <TableCell align="right">
                                                110g
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>

                <Box className="box2">
                    <h3>レシピ</h3>
                    <RecipeStep {...{ stepState, setStepState }} />
                </Box>
            </Box>
        </div>
    );
}
export default Recipe;
