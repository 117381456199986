import React from 'react';
import './material.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { sizing } from '@material-ui/system';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

function Material() {
    return (
        <Box className="material" display="inline-block">
            <TableContainer>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <div className="border"></div>ベーコン
                            </TableCell>
                            <TableCell align="right">6枚</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <div className="border"></div>鶏むね肉
                            </TableCell>
                            <TableCell align="right">2枚</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <div className="border"></div>塩
                            </TableCell>
                            <TableCell align="right">小さじ１</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <div className="border"></div>コショウ
                            </TableCell>
                            <TableCell align="right">小さじ１</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <div className="border"></div>
                                ガーリックパウダー
                            </TableCell>
                            <TableCell align="right">小さじ１</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <div className="border"></div>玉ねぎ
                            </TableCell>
                            <TableCell align="right">2個</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default Material;
