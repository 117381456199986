import React, { useEffect, useState } from 'react';
import Misemonn from '../../components/Misemon';
import './Health.scss';
import { DropzoneArea, DropzoneAreaBase } from 'material-ui-dropzone';
import { DropzoneDialog } from 'material-ui-dropzone';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { spacing } from '@material-ui/system';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { sizing } from '@material-ui/system';
import { PagesSharp } from '@material-ui/icons';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Graph1 from './graf/graf1';
import Graph2 from './graf/graf2';
import Graph3 from './graf/graf3';
import Graph4 from './graf/graf4';
import Image from '../../images/dish1.jpg';
import Chef from '../../images/icons/chef.gif';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import Sidebar from '../../components/Sidebar';
import firebase from 'firebase';
import { useSelector } from 'react-redux';
import { func } from 'prop-types';

// function createData(name, calories, fat, carbs, protein) {
//     return { name, calories, fat, carbs, protein };
// }

// const rows = [
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//     createData('Eclair', 262, 16.0, 24, 6.0),
//     createData('Cupcake', 305, 3.7, 67, 4.3),
//     createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];

// function HealthGallary({ recipesId = [1, 2, 3], recipeId = 1 }) {
//     const tableRef = firebase.database().ref('RECIPE_TBL');
//     const recipeIdRef = tableRef.orderByChild('id').equalTo(recipeId);
//     return (
//         <div
//             style={{
//                 transform: 'translateX(-33%)',
//                 display: 'flex',
//                 flex: 'row nowrap',
//             }}
//         >
//             <img src={Image} alt="" />
//             <img src={Image} alt="" />
//             <img src={Image} alt="" />
//         </div>
//     );
// }

function Health() {
    function initial() {
        let initialState = {
            currentRecipe: {},
            breakfast: {},
            lunch: {},
            dinner: {},
        };
        if (localStorage.getItem('_willchef_health_state')) {
            initialState = JSON.parse(
                localStorage.getItem('_willchef_health_state'),
            );
        }
        return initialState;
    }

    const [state, setState] = useState(initial());
    const currentUser = useSelector((state) => state.global.currentUser);

    const { recipe_name, image, nu_energy } = state?.currentRecipe ?? {};
    const { breakfast, lunch, dinner } = state;

    useEffect(() => {
        let recipeRef;
        let uidRef;
        function callback(rows) {
            console.log('callback props : ', rows);
            const recipesId = rows.map((row) => row.recipe_id);
            console.log('recipes id : ', recipesId);
            recipeRef = firebase.database().ref('RECIPE_TBL');
            recipeRef.once('value', (snapshot) => {
                const rows = snapshot.val(); // array
                const rirekiRecipeRows = rows.filter((row) =>
                    recipesId.includes(row.id),
                );
                console.log('rireki recipe rows : ', rirekiRecipeRows);
                // (3) [{…}, {…}, {…}]
                // 0: {difficulty: 2, id: 1, image: "https://firebasestorage.googleapis.com/v0/b/ikkeit…=media&token=efdd64b6-8206-4a9b-b7af-89b913be83d4", money: 2100, nu_carbon: 27.7, …}
                // 1: {difficulty: 3, id: 2, image: "https://firebasestorage.googleapis.com/v0/b/ikkeit…=media&token=2aac0582-5f1a-4575-8c27-5f2ce2f2db85", money: 1800, nu_carbon: "17.7", …}
                // 2: {difficulty: 4, id: 3, image: "https://firebasestorage.googleapis.com/v0/b/ikkeit…=media&token=5541b1b5-9b19-42f6-b9f6-576b1439bcc4", money: 1450, nu_carbon: "15.45", …}
                // length: 3
                setState((prev) => ({
                    ...prev,
                    currentIndex: 0,
                    lastIndex: rirekiRecipeRows.length - 1,
                    currentRecipe: rirekiRecipeRows?.[0],
                    recipesId,
                    rirekiRecipeRows,
                }));
            });
        }

        const ref = firebase.database().ref('RIREKI_TBL');
        if (currentUser) {
            uidRef = ref.orderByChild('uid').equalTo(currentUser.uid);
            uidRef.once('value', function (snapshot) {
                const rows = snapshot.val();
                console.log('health rows values: ', rows);
                // if (rows) setState();
                // (3) [{…}, {…}, {…}]
                // 0: {recipe_id: 1, uid: "iu4qU4CyuKPDqQ51PDBoPVzxAdQ2", uid_recipe_id: "1_iu4qU4CyuKPDqQ51PDBoPVzxAdQ2", update_time: 1614143527876}
                // 1: {recipe_id: 2, uid: "iu4qU4CyuKPDqQ51PDBoPVzxAdQ2", uid_recipe_id: "2_iu4qU4CyuKPDqQ51PDBoPVzxAdQ2", update_time: 1614143535153}
                // 2: {recipe_id: 3, uid: "iu4qU4CyuKPDqQ51PDBoPVzxAdQ2", uid_recipe_id: "3_iu4qU4CyuKPDqQ51PDBoPVzxAdQ2", update_time: 1614144327481}
                // length: 3
                const rowsArray = rows ? Object.values(rows) : [];
                callback(rowsArray);
            });
        }

        return () => {
            recipeRef?.off();
            uidRef?.off();
        };
    }, [currentUser]);

    useEffect(() => {
        localStorage.setItem('_willchef_health_state', JSON.stringify(state));
        return () => {};
    }, [state]);

    function handleNext() {
        const nextIndex =
            state.currentIndex >= state.lastIndex ? 0 : state.currentIndex + 1;
        setState((prev) => ({
            ...prev,
            currentIndex: nextIndex,
            currentRecipe: prev.rirekiRecipeRows[nextIndex],
        }));
    }

    function handlePrev() {
        const nextIndex =
            state.currentIndex <= 0 ? state.lastIndex : state.currentIndex - 1;
        setState((prev) => ({
            ...prev,
            currentIndex: nextIndex,
            currentRecipe: prev.rirekiRecipeRows[nextIndex],
        }));
    }

    function handleAdd(corner) {
        if (state.lastIndex > -1) {
            setState((prev) => ({
                ...prev,
                [corner]: {
                    ...prev[corner],
                    [prev.currentRecipe.id]: prev.currentRecipe,
                },
            }));
        }
    }

    function handleDel(corner, id) {
        console.log('del', corner, id);
        setState((prev) => ({
            ...prev,
            [corner]: Object.fromEntries(
                Object.entries(prev[corner]).filter(
                    (item) => item[1].id !== id,
                ),
            ),
        }));
    }

    function showCornerNutrition(corner, nutrition) {
        return Object.values(state[corner]).reduce(function (sum, current) {
            return Number.parseInt(current[nutrition], 10) + sum;
        }, 0);
    }

    function takeInNutrition(nutrition) {
        return (
            showCornerNutrition('breakfast', nutrition) +
            showCornerNutrition('lunch', nutrition) +
            showCornerNutrition('dinner', nutrition)
        );
    }
    const sumNutrition = {
        nu_carbon: takeInNutrition('nu_carbon'),
        nu_energy: takeInNutrition('nu_energy'),
        nu_fat: takeInNutrition('nu_fat'),
        nu_fiber: takeInNutrition('nu_fiber'),
        nu_iron: takeInNutrition('nu_iron'),
        nu_mineral: takeInNutrition('nu_mineral'),
        nu_potassium: takeInNutrition('nu_potassium'),
        nu_protein: takeInNutrition('nu_protein'),
        nu_sodium: takeInNutrition('nu_sodium'),
        nu_vitamin: takeInNutrition('nu_vitamin'),
    };
    return (
        <div className="health">
            <Box display="inline-block" className="wrap1">
                <Box className="box1">
                    <h3>今日の食事</h3>
                </Box>
                <Box className="box2">
                    <h3>{recipe_name && recipe_name}</h3>
                    <Box className="cont1" display="inline-block">
                        <Box mx="auto" display="block" width="50px">
                            <IconButton color="primary" onClick={handleNext}>
                                <ArrowBackIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box className="cont2" display="inline-block">
                        <img src={image} alt="" />
                    </Box>
                    <Box className="cont3" display="inline-block">
                        <Box mx="auto" display="block" width="50px">
                            <IconButton color="primary" onClick={handleNext}>
                                <ArrowForwardIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
                <Box className="box3">
                    <TableContainer>
                        <Table size="small">
                            <TableBody>
                                <TableRow>
                                    <TableCell>エネルギー</TableCell>
                                    <TableCell align="right">
                                        {nu_energy}kcal
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box className="box4">
                    <ButtonGroup
                        variant="text"
                        color="primary"
                        size="large"
                        fullWidth="true"
                    >
                        <Button onClick={() => handleAdd('breakfast')}>
                            + 朝食
                        </Button>
                        <Button onClick={() => handleAdd('lunch')}>
                            + 昼食
                        </Button>
                        <Button onClick={() => handleAdd('dinner')}>
                            + 夕食
                        </Button>
                    </ButtonGroup>
                </Box>
                <Box className="box5">
                    <h3>朝食</h3>
                    <p>{showCornerNutrition('breakfast', 'nu_energy')}kcal</p>
                    <hr />

                    <TableContainer>
                        <Table size="small" aria-label="a dense table">
                            <TableBody>
                                {breakfast &&
                                    Object.values(breakfast).map((item) => (
                                        <TableRow>
                                            <TableCell padding="checkbox">
                                                <IconButton
                                                    color="primary"
                                                    onClick={() =>
                                                        handleDel(
                                                            'breakfast',
                                                            item.id,
                                                        )
                                                    }
                                                >
                                                    <HighlightOffIcon fontSize="small" />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell size="small">
                                                {item.recipe_name}
                                            </TableCell>
                                            <TableCell align="right">
                                                {item.nu_energy}kcal
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box className="box6">
                    <h3>昼食</h3>
                    <p>{showCornerNutrition('lunch', 'nu_energy')}kcal</p>
                    <hr />

                    <TableContainer>
                        <Table size="small" aria-label="a dense table">
                            <TableBody>
                                {lunch &&
                                    Object.values(lunch).map((item) => (
                                        <TableRow>
                                            <TableCell padding="checkbox">
                                                <IconButton
                                                    color="primary"
                                                    onClick={() =>
                                                        handleDel(
                                                            'lunch',
                                                            item.id,
                                                        )
                                                    }
                                                >
                                                    <HighlightOffIcon fontSize="small" />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell size="small">
                                                {item.recipe_name}
                                            </TableCell>
                                            <TableCell align="right">
                                                {item.nu_energy}kcal
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                <Box className="box7">
                    <h3>夕食</h3>
                    <p>{showCornerNutrition('dinner', 'nu_energy')}kcal</p>
                    <hr />

                    <TableContainer>
                        <Table size="small" aria-label="a dense table">
                            <TableBody>
                                {dinner &&
                                    Object.values(dinner).map((item) => (
                                        <TableRow>
                                            <TableCell padding="checkbox">
                                                <IconButton
                                                    color="primary"
                                                    onClick={() =>
                                                        handleDel(
                                                            'dinner',
                                                            item.id,
                                                        )
                                                    }
                                                >
                                                    <HighlightOffIcon fontSize="small" />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell size="small">
                                                {item.recipe_name}
                                            </TableCell>
                                            <TableCell align="right">
                                                {item.nu_energy}kcal
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
            <Box display="inline-block" className="wrap2">
                <Box className="box1">
                    <Box display="inline-block" className="graf1">
                        <Graph1 takeInEnergy={takeInNutrition('nu_energy')} />
                    </Box>
                    <Box display="inline-block" className="graf2">
                        <Graph2 sumNutrition={sumNutrition} />
                    </Box>
                    <Box display="inline-block" className="graf3">
                        <Graph3 />
                    </Box>
                </Box>
                <Box className="box2">
                    <Box display="inline-block" className="cont1">
                        <h3>足りない栄養を補う ヘルシーなレシピ</h3>
                        <img src={Image} alt="" />
                        <p>ほくほくジャガイモ肉じゃが</p>
                        <Box display="inline-block" className="div1">
                            <img src={Chef} alt="" />
                        </Box>
                        <Box display="inline-block" className="div2">
                            <p>
                                少し、塩分とカロリーを取りすぎているみたいですね。カリウムは塩分を分解する効果があるので、積極的に取りましょう！
                            </p>
                        </Box>
                    </Box>
                    <Box display="inline-block" className="cont2">
                        <Misemonn recipeId={6} />
                    </Box>
                </Box>
            </Box>
        </div>
    );
}

export default Health;
