/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-irregular-whitespace */
import React from 'react';
import './SearchResults.scss';
import { Button } from '@material-ui/core';
import Recipegrid from '../../components/RecipeGridv2';
import InputBase from '@material-ui/core/InputBase';
import {
    getClient,
    getScrollTop,
    waterFall as wF,
} from '../../utils/waterFall';
import Sidebar from '../../components/Sidebar';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import firebase from 'firebase';

const { useEffect, useState } = React;

function throttle(func, limit) {
    let inThrottle;
    return function (...args) {
        const context = this;
        if (!inThrottle) {
            inThrottle = true;
            func.apply(context, args);
            setTimeout(() => {
                inThrottle = false;
            }, limit);
        }
    };
}

function debounce(func, wait, immediate) {
    let timeout;
    return function (...args) {
        const context = this;
        const later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}
// Throttle 連続に呼ばれた時、一定時間ごとに最初の一回だけを実行
const waterFallThrottle = throttle(wF, 50);
// debounce 連続に呼ばれた時、一定時間内に呼ばれていない時に実行
const waterFallDebounce = debounce(wF, 3, true);

let items;
let box;
let gap;

function getQueryString(str, key) {
    if (str) {
        const queryString = str.split('?')[1] || '';
        const arr = queryString.split('&') || [];
        for (let i = 0; i < arr.length; i++) {
            const keyString = decodeURIComponent(arr[i].split('=')[0]);
            const valueString = decodeURIComponent(arr[i].split('=')[1]);
            if (key === keyString) {
                return valueString;
            }
        }
        return null;
    }
    return null;
}

function SearchResults() {
    const history = useHistory();
    const param = useParams();
    const location = useLocation();
    console.log('history&param', history, param);

    const [state, setstate] = useState(null);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        console.log(getQueryString(location.search, 'q'));
        const textQuery = getQueryString(location.search, 'q');
        const tagsQuery = getQueryString(location.search, 'tags');
        console.log(
            `textQuery : ${typeof textQuery}, ${textQuery}, tagsQuery :${typeof tagsQuery}, ${tagsQuery}`,
        );
        // WHEN TEXT SEARCH
        const recipeTableRef = firebase.database().ref('RECIPE_TBL');
        if (textQuery !== null) {
            recipeTableRef.once('value', (snapshot) => {
                const rows = snapshot.val();
                let results = rows;

                results = rows.filter((item) =>
                    item?.recipe_name.includes(textQuery),
                );
                console.log('rows : ', rows);
                console.log('results : ', results);

                results.forEach((item) => {
                    console.log('string query setting temp obj');
                    setstate((prev) => ({
                        ...prev,
                        [item.id]: item.id,
                    }));
                });
                setLoading(false);
            });
        }

        // WHEN TAGS SEARCH
        const zairopTagNumRef = firebase.database().ref('RECIPE_ZAIRYO_TBL');
        console.log('tagsQuery', typeof tagsQuery, tagsQuery);
        if (tagsQuery !== null) {
            console.log('tagsQuery : ', tagsQuery.split('+'));
            const tagsIntQuery = tagsQuery
                .split('+')
                .map((item) => Number.parseInt(item, 10));
            zairopTagNumRef.once('value', (snapshot) => {
                let rows = snapshot.val();
                console.log('tagsQuery rows', rows);
                console.log('tags in query : ', tagsIntQuery);
                rows = rows.filter((row) => tagsIntQuery.includes(row.tag_num));
                console.log('filtered rows', rows);
                let tempObj = {};
                rows.forEach((row) => {
                    tempObj = {
                        ...tempObj,
                        [row.id]: row.id,
                    };
                });
                setstate((prev) => ({
                    ...prev,
                    ...tempObj,
                }));
                setLoading(false);
            });
        }

        return () => {
            recipeTableRef?.off();
            zairopTagNumRef?.off();
        };
    }, []);

    useEffect(() => {
        console.log('state : ', state);
        return () => {};
    }, [state]);

    useEffect(() => {
        box = document.getElementById('box');
        items = document.getElementById('box').children;
        gap = 0;
        window.addEventListener('resize', waterFallThrottle);

        return () => {
            window.removeEventListener('resize', waterFallThrottle);
        };
    }, []);

    return (
        <div
            className="searchResult"
            onLoad={wF}
            style={{ position: 'relative', minHeight: '100%' }}
        >
            {loading === true && (
                <div
                    style={{
                        position: 'absolute',
                        zIndex: 10,
                        width: '100%',
                        height: '100px',
                        top: '45%',
                        fontSize: '20px',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        textAlign: 'center',
                    }}
                >
                    Loading...
                </div>
            )}
            <div
                className="container"
                id="box"
                style={{ position: 'relative' }}
            >
                {state &&
                    Object.values(state).map((recipeId) => (
                        <div key={recipeId}>
                            <Button
                                onClick={() => {
                                    history.push('/recipe/' + recipeId);
                                }}
                            >
                                <Recipegrid recipeId={recipeId} />
                            </Button>
                        </div>
                    ))}
                {state &&
                    Object.values(state).map((recipeId) => (
                        <div key={recipeId}>
                            <Button
                                onClick={() => {
                                    history.push('/recipe/' + recipeId);
                                }}
                            >
                                <Recipegrid recipeId={recipeId} />
                            </Button>
                        </div>
                    ))}
                {state &&
                    Object.values(state).map((recipeId) => (
                        <div key={recipeId}>
                            <Button
                                onClick={() => {
                                    history.push('/recipe/' + recipeId);
                                }}
                            >
                                <Recipegrid recipeId={recipeId} />
                            </Button>
                        </div>
                    ))}
                {state &&
                    Object.values(state).map((recipeId) => (
                        <div key={recipeId}>
                            <Button
                                onClick={() => {
                                    history.push('/recipe/' + recipeId);
                                }}
                            >
                                <Recipegrid recipeId={recipeId} />
                            </Button>
                        </div>
                    ))}
                {state &&
                    Object.values(state).map((recipeId) => (
                        <div key={recipeId}>
                            <Button
                                onClick={() => {
                                    history.push('/recipe/' + recipeId);
                                }}
                            >
                                <Recipegrid recipeId={recipeId} />
                            </Button>
                        </div>
                    ))}
            </div>
        </div>
    );
}

// 例如，用 JSX 编写的代码：

// class Hello extends React.Component {
//   render() {
//     return <div>Hello {this.props.toWhat}</div>;
//   }
// }

// ReactDOM.render(
//   <Hello toWhat="World" />,
//   document.getElementById('root')
// );
// 可以编写为不使用 JSX 的代码：

// class Hello extends React.Component {
//   render() {
//     return React.createElement('div', null, `Hello ${this.props.toWhat}`);
//   }
// }

// ReactDOM.render(
//   React.createElement(Hello, {toWhat: 'World'}, null),
//   document.getElementById('root')
// );

export default SearchResults;
