/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-plusplus */
/* eslint-disable no-sequences */
/* eslint-disable no-undef */
/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
/* eslint-disable no-loop-func */
/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import './Review.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { spacing, sizing } from '@material-ui/system';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddReview from '../images/icons/plus.gif';
import Dish from '../images/dish2.jpg';

function Review() {
    return (
        <div className="review">
            <div className="box">
                <Button>
                    <img className="add" src={AddReview} alt="" />
                </Button>
            </div>
            <div className="box">
                <img src={Dish} alt="" />
                <p>
                    すこし塩分が効きすぎているので、我が家では塩をポン酢に変えて作っています！！とってもおいしくてお気に入りの一品です
                </p>
            </div>
            <div className="box">
                <img src={Dish} alt="" />
                <p>
                    すこし塩分が効きすぎているので、我が家では塩をポン酢に変えて作っています！！とってもおいしくてお気に入りの一品です
                </p>
            </div>{' '}
            <div className="box">
                <img src={Dish} alt="" />
                <p>
                    すこし塩分が効きすぎているので、我が家では塩をポン酢に変えて作っています！！とってもおいしくてお気に入りの一品です
                </p>
            </div>{' '}
            <div className="box">
                <img src={Dish} alt="" />
                <p>
                    すこし塩分が効きすぎているので、我が家では塩をポン酢に変えて作っています！！とってもおいしくてお気に入りの一品です
                </p>
            </div>{' '}
            <div className="box">
                <img src={Dish} alt="" />
                <p>
                    すこし塩分が効きすぎているので、我が家では塩をポン酢に変えて作っています！！とってもおいしくてお気に入りの一品です
                </p>
            </div>{' '}
            <div className="box">
                <img src={Dish} alt="" />
                <p>
                    すこし塩分が効きすぎているので、我が家では塩をポン酢に変えて作っています！！とってもおいしくてお気に入りの一品です
                </p>
            </div>{' '}
            <div className="box">
                <img src={Dish} alt="" />
                <p>
                    すこし塩分が効きすぎているので、我が家では塩をポン酢に変えて作っています！！とってもおいしくてお気に入りの一品です
                </p>
            </div>{' '}
            <div className="box">
                <img src={Dish} alt="" />
                <p>
                    すこし塩分が効きすぎているので、我が家では塩をポン酢に変えて作っています！！とってもおいしくてお気に入りの一品です
                </p>
            </div>{' '}
            <div className="box">
                <img src={Dish} alt="" />
                <p>
                    すこし塩分が効きすぎているので、我が家では塩をポン酢に変えて作っています！！とってもおいしくてお気に入りの一品です
                </p>
            </div>{' '}
            <div className="box">
                <img src={Dish} alt="" />
                <p>
                    すこし塩分が効きすぎているので、我が家では塩をポン酢に変えて作っています！！とってもおいしくてお気に入りの一品です
                </p>
            </div>{' '}
            <div className="box">
                <img src={Dish} alt="" />
                <p>
                    すこし塩分が効きすぎているので、我が家では塩をポン酢に変えて作っています！！とってもおいしくてお気に入りの一品です
                </p>
            </div>{' '}
            <div className="box">
                <img src={Dish} alt="" />
                <p>
                    すこし塩分が効きすぎているので、我が家では塩をポン酢に変えて作っています！！とってもおいしくてお気に入りの一品です
                </p>
            </div>{' '}
        </div>
    );
}

export default Review;
