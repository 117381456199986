import React, { PureComponent, useState } from 'react';
import { PieChart, Pie, Sector, Cell } from 'recharts';

const renderActiveShape = (props) => {
    const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        fill,
        payload,
        percent,
        value,
        consumeEnergy,
    } = props;

    return (
        <g>
            <text x={cx} y={cy - 30} dy={8} textAnchor="middle" fill="#FE93BC">
                摂取カロリー
            </text>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill="#000">
                ＆
            </text>
            <text x={cx} y={cy + 30} dy={8} textAnchor="middle" fill="#4C7788">
                消費カロリー
            </text>
        </g>
    );
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.3;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text
            x={x}
            y={y}
            fill="#fff"
            textAnchor={x > cx ? 'start' : 'end'}
            dominantBaseline="central"
        >
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

export default function Grap1({ takeInEnergy }) {
    const jsfiddleUrl = 'https://jsfiddle.net/alidingling/w6wsrc52/';
    const COLORS = ['#4C7788', '#FE93BC'];
    const data01 = [{ name: 'Group A', value: 800 }];
    const data02 = [
        { id: '1', name: '消費カロリー', value: 1800 },
        { id: '2', name: '摂取カロリー', value: takeInEnergy },
    ];
    const [state, setState] = useState({
        activeIndex: 0,
    });

    const onPieEnter = (data, index) => {
        setState({
            activeIndex: index,
        });
    };

    const { activeIndex } = state;
    return (
        <PieChart width={250} height={250}>
            <Pie
                data={data02}
                cx={120}
                startAngle={90}
                endAngle={450}
                cy={120}
                innerRadius={75}
                outerRadius={500}
                fill="#8884d8"
                dataKey="value"
                minAngle={5}
            >
                {data02.map((entry, index) => (
                    <Cell
                        key={`${entry.id}`}
                        fill={COLORS[index % COLORS.length]}
                    />
                ))}
            </Pie>

            <Pie
                data={data02}
                cx={120}
                startAngle={90}
                endAngle={450}
                cy={120}
                innerRadius={50}
                outerRadius={75}
                fill="#ffffff"
                label={renderCustomizedLabel}
                labelLine={false}
                minAngle={5}
                dataKey="value"
            ></Pie>
            <Pie
                data={data01}
                activeIndex={activeIndex}
                activeShape={renderActiveShape}
                dataKey="value"
                cx={120}
                cy={120}
                outerRadius={75}
                fill="#222"
            />
        </PieChart>
    );
}
