import { searchReducer } from './redux/reducers';

const APP_ID = 'f3ea5cde';
const APP_KEY = 'd6434d94a184f59b392e387b81d0b49b';
const searchText = 'beef';
// const Req = `https://api.edamam.com/search?q=chicken&app_id=${APP_ID}&app_key=${APP_KEY}&from=0&to=3&calories=591-722&health=alcohol-free`;
const Req = `https://api.edamam.com/search?q=${searchText}&app_id=${APP_ID}&app_key=${APP_KEY}&from=0&to=18`;

const firebaseURL = `https://ikkeitest.firebaseio.com`;
export const allTagsURL = () => {
    // const url=`https://ikkeitest.firebaseio.com/TAG_TBL.json?orderBy=%22tag_num%22&startAt=1`
    return `${firebaseURL}/TAG_TBL.json`;
};
export const lastGroupNumURL = () => {
    const url = `${firebaseURL}/TAG_TBL.json?orderBy="group_num"&limitToLast=1`;
};
export const searchURL = (text = searchText) => {
    return `https://api.edamam.com/search?q=${text}&app_id=${APP_ID}&app_key=${APP_KEY}&from=0&to=18`;
};
