/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useContext } from 'react';
import './index.scss';
import { Button } from '@material-ui/core';
import { Redirect, Route, Switch } from 'react-router-dom';
// import UserContext from '../../pureLogic/UserContext';
import Sidebar from '../../components/Sidebar';
import TopPage from './FirstPage';
import Search from './Search';
import SearchResult from './SearchResults';
import Recipe from './Recipe';
import Health from './Health';
import CookBook from './CookBook';
import MakeNewRecipe from './MakeNewRecipe';
import MyPage from './MyPage';
import Misc from './misc/index';
import { useDispatch } from 'react-redux';
import {
    fetchAllTags,
    fetchLastGroupNum,
} from '../../redux/actions/searchActions';

function MainPage() {
    // REDUX

    // const dispatch = useDispatch();
    // useEffect(() => {
    //     dispatch(fetchAllTags());
    // }, []);

    // useEffect(() => {
    //     dispatch(fetchLastGroupNum());
    // }, []);

    return (
        <>
            <Switch>
                <Route path="/misc" render={(props) => <Misc {...props} />} />
                <Route path="/">
                    <div className="mainPage">
                        <Sidebar />
                        <main>
                            <Switch>
                                <Route
                                    exact
                                    path="/"
                                    render={(props) => <TopPage {...props} />}
                                />
                                <Route
                                    exact
                                    path="/search"
                                    render={(props) => <Search {...props} />}
                                />
                                <Route path="/search-results/:searchText?">
                                    <SearchResult />
                                </Route>
                                <Route
                                    path="/recipe/:id?"
                                    render={(props) => <Recipe {...props} />}
                                />
                                <Route
                                    exact
                                    path="/health"
                                    render={(props) => <Health {...props} />}
                                />
                                <Route
                                    path="/cookbook"
                                    render={(props) => <CookBook {...props} />}
                                />
                                <Route
                                    exact
                                    path="/make-new-recipe"
                                    render={(props) => (
                                        <MakeNewRecipe {...props} />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/mypage"
                                    render={(props) => <MyPage {...props} />}
                                />
                            </Switch>
                        </main>
                    </div>
                </Route>
                {/* {!isLoggedIn && (
                <>
                    <Redirect to="/login" />
                </>
            )} */}
            </Switch>
        </>
    );
}

export default MainPage;
