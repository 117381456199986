// const box = document.getElementById('box');
// console.log(box);
// const items = box.children;
// // 定义每一列之间的间隙 为10像素
// const gap = 10;

// clientWidth 处理兼容性
export function getClient() {
    return {
        width:
            //     (window.innerWidth ||
            //     document.documentElement.clientWidth ||
            //     document.body.clientWidth) -
            // document.querySelector('.sidebar').offsetWidth,
            document.getElementById('box').offsetWidth,
        height:
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight,
    };
}
// scrollTop兼容性处理
export function getScrollTop() {
    return window.pageYOffset || document.documentElement.scrollTop;
}

export function waterFall(box, items, gap) {
    box = box || document.getElementById('box');
    items = items || document.getElementById('box').children;
    gap = gap || 5;
    const colGap = 20;
    // 1- 确定列数  = 页面的宽度 / 图片的宽度
    const pageWidth = getClient().width;
    const itemWidth = items[0].offsetWidth;
    const columns = parseInt((pageWidth - gap) / (itemWidth + gap), 10);
    gap = (pageWidth - columns * itemWidth) / (columns + 1);
    const arr = [];
    // console.log(
    //     `pageWidth:${pageWidth}, itemWidth:${itemWidth}, columns:${columns}`,
    // );
    for (let i = 0; i < columns; i++) {
        arr[i] = -colGap;
    }
    for (let i = 0; i < items.length; i++) {
        if (false) {
            // 2- 确定第一行
            items[i].style.top = 0;
            items[i].style.left = `${(itemWidth + gap) * i + gap}px`;

            arr.push(items[i].offsetHeight);
        } else {
            // 其他行
            // 3- 找到数组中最小高度  和 它的索引
            let minHeight = arr[0];
            let index = 0;

            for (let j = 0; j < arr.length; j++) {
                if (minHeight > arr[j]) {
                    minHeight = arr[j];
                    index = j;
                }
            }
            // 4- 设置下一行的第一个盒子位置
            // top值就是最小列的高度 + gap
            for (let k = 0; k < arr.length; k++) {
                // console.log(`arr[${k}]:${arr[k]}`);
            }
            // console.log(`index:${index}, arr[${index}]:${arr[index]}`);
            items[i].style.top = `${arr[index] + colGap}px`;
            // left值就是最小列距离左边的距离
            // items[i].style.left = `${items[index].offsetLeft}px`;
            // items[i].style.left = `${
            //     (itemWidth + gap) * (i % columns) + gap
            // }px`;
            // items[i].style.left = `${items[index].offsetLeft}px`;
            items[i].style.left = `${
                (itemWidth + gap) * (index % columns) + gap
            }px`;

            // 5- 修改最小列的高度
            // 最小列的高度 = 当前自己的高度 + 拼接过来的高度 + 间隙的高度
            arr[index] = arr[index] + items[i].offsetHeight + colGap;
        }
    }
}

// // 页面尺寸改变时实时触发
// window.onresize = function () {
//     waterFall();
// };
// // 当加载到第30张的时候
// window.onscroll = function () {
//     if (
//         getClient().height + getScrollTop() >=
//         items[items.length - 1].offsetTop
//     ) {
//         // 模拟 ajax 获取数据
//         const datas = ['../image/瀑布流/001.jpg', ...'../image/瀑布流/030.jpg'];
//         for (let i = 0; i < datas.length; i++) {
//             const div = document.createElement('div');
//             div.className = 'item';
//             div.innerHTML = `<img src="${datas[i]}" alt="">`;
//             box.appendChild(div);
//         }
//         waterFall();
//     }
// };
export default waterFall;
