import React, { useEffect, useState } from 'react';
import Misemonn from '../../components/Misemon';
import './FirstPage.scss';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { spacing } from '@material-ui/system';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { sizing } from '@material-ui/system';
import { PagesSharp } from '@material-ui/icons';

import Sidebar from '../../components/Sidebar';
import firebase from 'firebase';

function FirstPage(prps) {
    const recipeId = 6;
    const [state, setState] = useState({});
    const {
        id,
        recipe_name,
        difficulty,
        image,
        markCount,
        reviewCount,
        pro,
        time,
        money,
        setumei,
        nu_carbon,
        nu_energy,
        nu_fat,
        nu_protein,
    } = state;

    useEffect(() => {
        console.log('recip grid get id : ', typeof recipeId, recipeId);
        const tagRef = firebase.database().ref('RECIPE_TBL');
        const recipeIdRef = tagRef.orderByChild('id').equalTo(recipeId);
        recipeIdRef.once('value', (snapshot) => {
            const val = snapshot.val();
            console.log(val);
            if (val) {
                const row = Object.values(val)[0];
                setState({
                    ...state,
                    ...row,
                });
            }
        });
        return () => {};
    }, []);

    return (
        <div className="firstPage">
            <Box className="todayMenu" display="inline-block">
                <Box>
                    <h3>~ Today`s Special Menu ~</h3>
                    <h4>
                        {new Date().getMonth() + 1}月{new Date().getDate()}日（
                        {
                            ['日', '月', '火', '水', '木', '金', '土'][
                                new Date().getDay()
                            ]
                        }
                        曜日）
                    </h4>
                    <img src={image} alt="" />
                    <h2>{recipe_name}</h2>
                    <p>{setumei}</p>
                </Box>
            </Box>
            <Box className="mise-container">
                <Misemonn recipeId={6} />
            </Box>
        </div>
    );
}

export default FirstPage;
