import React from 'react';
import './CookBook.css';
import Recipe from '../../components/RecipeGridv2';
import NewRecipe from '../../images/icons/newrecipe_white.gif';
import Edit from '../../images/icons/edit.gif';
import Download from '../../images/icons/download.gif';
import { DropzoneArea, DropzoneAreaBase } from 'material-ui-dropzone';
import { DropzoneDialog } from 'material-ui-dropzone';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { spacing } from '@material-ui/system';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { sizing } from '@material-ui/system';
import { PagesSharp } from '@material-ui/icons';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import CookBookDetail from './CookBookDetail';
import { Route } from 'react-router-dom';

import firebase from 'firebase';

import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';

// components
import ImgChecked from '../../images/icons/EllipseCheck.png';
import ImgUnChecked from '../../images/icons/EllipseUncheck.png';

function GridContainer({ recipeId, ...rest }) {
    return (
        <div className="edit-container" {...rest}>
            <div className="edit-panel">
                <img src={ImgChecked} alt="" />
                <img src={ImgUnChecked} alt="" />
            </div>
            <Recipe recipeId={Number.parseInt(recipeId, 10)} />
        </div>
    );
}

function CookBook(props) {
    const { history } = props;
    const currentUser = useSelector((state) => state.global.currentUser);
    const [state, setState] = useState([]);

    useEffect(() => {
        const ref = firebase.database().ref('COOKBOOK_TBL');
        if (currentUser) {
            const uidRef = ref.orderByChild('uid').equalTo(currentUser.uid);
            uidRef.once('value', function (snapshot) {
                console.log(snapshot.val());
                const rows = snapshot.val();
                console.log('cookbook rows : ', rows);
                if (rows) setState(Object.values(rows));
            });
        }
        return () => {};
    }, []);

    function handlePrint() {
        const printPages = state.map((item) => item.recipe_id);
        let jumpTo = '/misc/print/?recipe_id=';
        console.log('printPages : ', printPages);
        if (printPages?.length > 0) {
            printPages.forEach((item) => {
                jumpTo += `${Number.parseInt(item, 10)}+`;
            });
            jumpTo = jumpTo.slice(0, -1);
            history.push(jumpTo);
        }
    }

    return (
        <>
            <div className="cookbook">
                <Box className="wrap1" display="inline-block" width="30%">
                    <Box className="box1">
                        <Button onClick={handlePrint}>
                            <img src={Download} alt="" />
                        </Button>
                    </Box>
                    <Box className="box2">
                        <Button>
                            <img src={Edit} alt="" />
                        </Button>
                    </Box>
                </Box>
                <Box className="wrap2" display="inline-block" width="70%">
                    <Box className="box1">
                        {state &&
                            state.map((obj) => (
                                <GridContainer recipeId={obj.recipe_id} />
                            ))}
                        {state.length == 0 && (
                            <div className="no-cookbook">no cookbook</div>
                        )}
                    </Box>
                </Box>
            </div>
        </>
    );
}

// export default CookBook;

// const FlexFrame = (props) => (
//     <div className="mainPage">
//         <Sidebar sidebarWidth="compact" />
//         <main>{props.children}</main>
//     </div>
// );

// const OutPut = (props) => {
//     console.log('get in cookbook page');
//     return (
//         <FlexFrame>
//             <CookBook {...props} />
//         </FlexFrame>
//     );
// };

// export default OutPut;

// export const withSidebar = (Component) => (props) => (
//     <div className="mainPage">
//         <Sidebar sidebarWidth="compact" />
//         <main>
//             <Component {...props} />
//         </main>
//     </div>
// );

export default CookBook;
