import React from 'react';
import './UserInfoChange.css';
import { DropzoneArea, DropzoneAreaBase } from 'material-ui-dropzone';
import { DropzoneDialog } from 'material-ui-dropzone';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { spacing } from '@material-ui/system';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { sizing } from '@material-ui/system';
import { PagesSharp } from '@material-ui/icons';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

function UserInfoChange({ history }) {
    return (
        <div className="userInfoChange ikLazyCss">
            <h2>ユーザー情報変更</h2>
            <div className="wrap">
                <form>
                    <h3>パスワード変更</h3>
                    <p>現在のパスワード</p>
                    <TextField
                        fullWidth
                        size="small"
                        type="password"
                        id="filled-basic"
                        label="現在のパスワードを入力してください"
                        variant="filled"
                    />

                    <p>新しいパスワード</p>
                    <h4>
                        ※ 半角英数字8~12文字で、英語と数字を組み合わせてください
                    </h4>
                    <TextField
                        fullWidth
                        size="small"
                        type="password"
                        id="filled-basic"
                        label="新しいパスワードを入力してください"
                        variant="filled"
                    />

                    <h3>メールアドレス変更</h3>
                    <TextField
                        fullWidth
                        size="small"
                        id="filled-basic"
                        label="新しいメールアドレスを入力してください"
                        variant="filled"
                    />

                    <h3>ユーザー名変更</h3>
                    <TextField
                        fullWidth
                        size="small"
                        type="password"
                        id="filled-basic"
                        label="新しいユーザー名を入力してください"
                        variant="filled"
                    />

                    <Box display="block" mx="auto" my={7} width="350px">
                        <Box display="inline-block">
                            <Button
                                variant="outlined"
                                size="large"
                                onClick={() => {
                                    history.push('/mypage');
                                }}
                            >
                                もどる
                            </Button>
                        </Box>
                        <Box display="inline-block" ml={20}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                            >
                                変更
                            </Button>
                        </Box>
                    </Box>
                </form>
            </div>
        </div>
    );
}

export default UserInfoChange;
