import React from 'react';
import { useHistory } from 'react-router-dom';
import img from '../../../images/pasta.jpg';
import './printpage.css';
import Material from '../../../components/material';

import Memo from '../../../components/Memo';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import Silver from '../../../images/icons/normal.gif';
import Time from '../../../images/icons/time_15.gif';
import Money from '../../../images/icons/money.gif';
import Pro from '../../../images/icons/pro.gif';
import Graf from '../graf/graf2';

import RecipeStep from '../../../components/step_old';

function PrintPage({ hit, theRecipe }) {
    console.log(theRecipe, hit);
    if (hit === undefined) {
        hit = theRecipe;
    }
    const { label, image, calories, totalTime, ingredients } =
        hit?.recipe || {};

    console.log('get in PrintPage.jsx');

    const history = useHistory();
    return (
        <div
            onClick={() => {
                history.goBack();
            }}
            className="print-view"
        >
            <div style={{ breakBefore: 'page' }}>
                <div className="recipeprintpages">
                    <Box>
                        <Box>
                            <Box width="1020px" mx="auto">
                                <img src={img} alt="" />
                                <Box
                                    display="inline-block"
                                    className="box"
                                    id="mem"
                                >
                                    <Memo></Memo>
                                </Box>
                                <Box display="inline-block" width="300px"></Box>
                                <Box
                                    className="icon"
                                    width="320px"
                                    display="inline-block"
                                ></Box>
                                <h2 className="cookName">
                                    濃厚チーズのクリームチキンパスタ
                                </h2>
                            </Box>
                        </Box>
                    </Box>

                    <Box>
                        <Box id="Mate">
                            <h3>材料（一人前）</h3>
                            <Material></Material>
                            {/* <Material></Material> */}
                            {/* <Material></Material> */}
                            {/* <Material></Material> */}
                        </Box>
                        <Box id="ReciSte">
                            <h3>作り方</h3>
                            <RecipeStep></RecipeStep>
                        </Box>

                        {/* <Box display="inline-block" className="box">
                            <Memo></Memo>
                        </Box>
                        <Box display="inline-block" className="box">
                            <Memo></Memo>
                        </Box> */}
                    </Box>
                </div>
            </div>
        </div>
    );
}

export default PrintPage;
