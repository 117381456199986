// import React, { useState } from 'react';
// import './LogIn.css';
// import { Button } from '@material-ui/core';
import SignUp from './SignUp';
import { Launch } from '@material-ui/icons';
import topLogo from '../../images/title.png'
import chef from '../../images/chef.png'
import sample1 from "../../images/sample1.png";
import sample2 from "../../images/sample2.png";
import foods from "../../images/foods.jpg";
import fb from "../../images/fitbit.jpg";

/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { auth } from '../../firebase';
import authActions from '../../redux/actions/authActions';
import { Box, Button, TextField } from '@material-ui/core';
import './Login.css';
import { useHistory } from 'react-router-dom';

function LogIn({ setIsLogin }) {
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.global.currentUser);

    const history = useHistory();

    function login(email, password) {
        return auth.signInWithEmailAndPassword(email, password);
    }

    async function logOutHandler(event) {
        event.preventDefault();
        try {
            console.log('log out');

            await auth.signOut();
        } catch (err) {
            alert(err.message);
        }
    }
    async function logInHandler(e) {
        e.preventDefault();
        console.log(e.target);
        const form = e.target;
        const email = form.querySelector("input[id='email']").value;
        const password = form.querySelector("input[id='password']").value;
        console.log('email', email, 'password', password);
        try {
            await auth.signInWithEmailAndPassword(email, password);
            console.log('ok');
        } catch (err) {
            console.log(err);
            alert(err.message);
        }
    }
    React.useEffect(() => {
        console.log(currentUser);
        return () => { };
    }, [currentUser]);

    function visibleHandler(e) {
        if (e.target.id === 'LoginForm') {
            setIsLogin((prev) => !prev);
        }
    }
    return (
        <>
            <div id="LoginForm" onClick={visibleHandler}>
                <form onSubmit={logInHandler}>
                    <h2>Will Chef へようこそ</h2>
                    <Box className="box1">
                        <TextField
                            className="outlined-basic"
                            id="email"
                            label="ログインID（メールアドレス）"
                            variant="outlined"
                            type="mail"
                            fullWidth
                            required
                        />
                    </Box>

                    <Box className="box2">
                        <TextField
                            id="password"
                            className="outlined-basic"
                            label="パスワード"
                            variant="outlined"
                            type="password"
                            fullWidth
                            required
                        />
                    </Box>

                    <Box className="box3">
                        <Box className="contant1">
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => history.push('/signup')}
                            >
                                新規会員登録をする
                            </Button>
                        </Box>
                        <Box className="contant2">
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                ログインする
                            </Button>
                        </Box>
                    </Box>
                </form>
            </div>

            {/* <p>{'uid : ' + { ...currentUser }?.uid}</p> */}
        </>
    );
}

export default LogIn;
