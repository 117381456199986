/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import './Step2.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { spacing, sizing } from '@material-ui/system';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { IconButton } from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { getElementError } from '@testing-library/react';
import Mov from '../images/mov/nikujaga.gif';
import Material from './material';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
}));
function getSteps() {
    return ['STEP 1', 'STEP 2', 'STEP 3', 'STEP 4', 'STEP 5', 'STEP 6'];
}

function getStepContent(step) {
    switch (step) {
        case 0:
            return '玉ねぎはくし切りで、おああああああああ肉を一口大に切ります';
        case 1:
            return '軽く油を熱して、お肉を炒めます';
        case 2:
            return '野菜も加えて炒め合わせ全体に油がまわったら砂糖とお水を注ぎ入れます';
        case 3:
            return '合わせ調味料も加えて落としぶたをして、弱火で20ー30分煮込みます';
        case 4:
            return 'じゃがいもとにんじんに箸がスッと通れば完成です';
        case 5:
            return '軽く油を熱して、お肉を炒めます';
        case 6:
            return '野菜も加えて炒め合わせ全体に油がまわったら砂糖とお水を注ぎ入れます';
        default:
            return 'Unknown step';
    }
}

function RecipeStep({ stepState, setStepState }) {
    const classes = useStyles();
    const steps = getSteps();
    let getStep = {};
    if (stepState) {
        getStep = stepState;
    }
    const { activeStep, lastStepCount, allStep, complete } = getStep;

    return (
        <>
            {stepState && (
                <div className="recipeStep">
                    <div className="reciperoot">
                        {allStep && (
                            <Stepper
                                activeStep={
                                    complete ? activeStep : activeStep - 1
                                }
                                orientation="vertical"
                                className="stepper"
                            >
                                {Object.values(allStep).map((step) => (
                                    <Step key={step.renban}>
                                        <StepLabel>{step.text}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        )}
                        {complete && (
                            <div className="step-complete">
                                congrats!!! おめでとう！
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

export default RecipeStep;
