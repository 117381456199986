import React from 'react';
import './FitbitSetting.css';
import { DropzoneArea, DropzoneAreaBase } from 'material-ui-dropzone';
import { DropzoneDialog } from 'material-ui-dropzone';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { spacing } from '@material-ui/system';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { sizing } from '@material-ui/system';
import { PagesSharp } from '@material-ui/icons';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

function FitbitSetting({ history }) {
    const siteUrl =
        'https%3A%2F%2Fwillchef.netlify.app%2Fmisc%2Ffitbit-setting';
    const authurl = `https://www.fitbit.com/oauth2/authorize?response_type=token&client_id=22C76B&redirect_uri=${siteUrl}&scope=activity%20heartrate%20location%20nutrition%20profile%20settings%20sleep%20social%20weight&expires_in=604800`;
    const accessurl =
        'http://localhost/#access_token=eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyMkM3NkIiLCJzdWIiOiI5NUtHU1YiLCJpc3MiOiJGaXRiaXQiLCJ0eXAiOiJhY2Nlc3NfdG9rZW4iLCJzY29wZXMiOiJ3aHIgd3BybyB3bnV0IHdzbGUgd3dlaSB3c29jIHdzZXQgd2FjdCB3bG9jIiwiZXhwIjoxNjExOTA3NTEyLCJpYXQiOjE2MTEzMDI3MTJ9.ObjGMx8WIfiCY9wBr0JF-vMTxsugHcZ3dxuXCqdf-cI&user_id=95KGSV&scope=heartrate+profile+settings+nutrition+weight+location+activity+sleep+social&token_type=Bearer&expires_in=604800';
    const access_token =
        'eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyMkM3NkIiLCJzdWIiOiI5NUtHU1YiLCJpc3MiOiJGaXRiaXQiLCJ0eXAiOiJhY2Nlc3NfdG9rZW4iLCJzY29wZXMiOiJ3aHIgd3BybyB3bnV0IHdzbGUgd3dlaSB3c29jIHdzZXQgd2FjdCB3bG9jIiwiZXhwIjoxNjExOTA3NTEyLCJpYXQiOjE2MTEzMDI3MTJ9.ObjGMx8WIfiCY9wBr0JF-vMTxsugHcZ3dxuXCqdf-cI';

    return (
        <div className="fitbitSetting ikLazyCss">
            <h2>fitbit連携設定</h2>
            <div className="wrap">
                <h3>利用中のfitbitアカウントと連携</h3>
            </div>
            <Box display="block" mx="auto" my={7} width="350px">
                <Box display="inline-block">
                    <Button
                        variant="outlined"
                        size="large"
                        onClick={() => {
                            history.push('/mypage');
                        }}
                    >
                        もどる
                    </Button>
                    <Box display="inline-block" ml={20}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => {
                                window.location.replace(authurl);
                            }}
                        >
                            連携する
                        </Button>
                    </Box>
                </Box>
            </Box>
        </div>
    );
}

export default FitbitSetting;
