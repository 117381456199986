import React, { PureComponent } from 'react';
import {
    Radar,
    RadarChart,
    PolarGrid,
    Legend,
    PolarAngleAxis,
    PolarRadiusAxis,
} from 'recharts';

// const data = [
//     {
//         subject: 'ビタミン',
//         A: 120,
//         B: 110,
//         fullMark: 150,
//     },
//     {
//         subject: '食物繊維',
//         A: 98,
//         B: 130,
//         fullMark: 150,
//     },
//     {
//         subject: '脂質',
//         A: 86,
//         B: 130,
//         fullMark: 150,
//     },
//     {
//         subject: '塩分',
//         A: 99,
//         B: 100,
//         fullMark: 150,
//     },
//     {
//         subject: 'タンパク質',
//         A: 85,
//         B: 90,
//         fullMark: 150,
//     },
//     {
//         subject: 'ミネラル',
//         A: 65,
//         B: 85,
//         fullMark: 150,
//     },
//     {
//         subject: 'カリウム',
//         A: 65,
//         B: 85,
//         fullMark: 150,
//     },
//     {
//         subject: '鉄分',
//         A: 65,
//         B: 85,
//         fullMark: 150,
//     },
// ];

export default function Graf2({ sumNutrition }) {
    const jsfiddleUrl = 'https://jsfiddle.net/alidingling/dpgb3xjq/';
    React.useEffect(() => {
        console.log('graph 1 props : ', sumNutrition);
        return () => {};
    }, []);

    const {
        nu_carbon,
        nu_energy,
        nu_fat,
        nu_fiber,
        nu_iron,
        nu_mineral,
        nu_potassium,
        nu_protein,
        nu_sodium,
        nu_vitamin,
    } = sumNutrition;

    const data = [
        {
            subject: 'ビタミン',
            A: nu_vitamin / 14,
            B: 110,
            fullMark: 150,
        },
        {
            subject: '食物繊維',
            A: nu_fiber / 8,
            B: 130,
            fullMark: 150,
        },
        {
            subject: '脂質',
            A: nu_fat * 2,
            B: 130,
            fullMark: 150,
        },
        {
            subject: '塩分',
            A: nu_sodium,
            B: 100,
            fullMark: 150,
        },
        {
            subject: 'タンパク質',
            A: nu_protein * 2,
            B: 90,
            fullMark: 150,
        },
        {
            subject: 'ミネラル',
            A: nu_mineral / 4,
            B: 85,
            fullMark: 150,
        },
        {
            subject: 'カリウム',
            A: nu_potassium / 2,
            B: 85,
            fullMark: 150,
        },
        {
            subject: '鉄分',
            A: nu_iron,
            B: 85,
            fullMark: 150,
        },
    ];

    return (
        <RadarChart
            cx={160}
            cy={110}
            outerRadius={70}
            width={300}
            height={250}
            data={data}
        >
            <PolarGrid stroke="#676767" />
            <PolarAngleAxis dataKey="subject" />
            {/* <PolarRadiusAxis angle={20} domain={[0, 100]} stroke="#676767" /> */}
            <Radar
                name="推奨値"
                dataKey="B"
                stroke="#FFDC8C"
                fill="#FFDC8C"
                fillOpacity={0.4}
            />
            <Radar
                name="摂取値"
                dataKey="A"
                stroke="#FE93BC"
                fill="#FE93BC"
                fillOpacity={0.4}
            />
            <Legend />
        </RadarChart>
    );
}
