import React, { useState, useEffect, useContext } from 'react';
import './App.scss';
import firebase from 'firebase';
import { firestore } from './firebase';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { purple } from '@material-ui/core/colors';
import {
    BrowserRouter as Router,
    useHistory,
    useLocation,
} from 'react-router-dom';
import SiteRouter from './SiteRouter';
// import Misc from './pages/mainPages/misc/index';
import AdminNavBar from './components/AdminNavBar';
// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { auth } from './firebase';
import SelectInput from '@material-ui/core/Select/SelectInput';

const theme = createMuiTheme({
    palette: {
        primary: {
            // Purple and green play nicely together.
            // main: '#4C7788',
            main: '#4C7788',
        },
        secondary: {
            // This is green.A700 as hex.
            main: '#4c7788',
        },
        error: {
            // This is green.A700 as hex.
            main: '#f44336',
        },
        warning: {
            // This is green.A700 as hex.
            main: '#ff9800',
        },
        info: {
            // This is green.A700 as hex.
            main: '#2196f3',
        },
        success: {
            // This is green.A700 as hex.
            main: '#4caf50',
        },
    },
});

function App() {
    const dispatch = useDispatch();
    const history = useHistory();

    // firebase 認証状態監視ファンクション
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            console.log('App.jsx auth change handle');
            console.log('dispath auth user', currentUser);
            dispatch({ type: 'UPDATE_AUTH', payload: { currentUser } });
            if (currentUser) {
                console.log('auth state changed to loggedin');
                localStorage.setItem('userId', currentUser.uid);
                // if (window.location.pathname === '/login' || '/intro') {
                //     history.push('/');
                // }
            } else {
                localStorage.removeItem('userId');
                history.push('/intro');
            }
        });
        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <ThemeProvider theme={theme}>
            {/* <UserContext.Provider value={{ isLogIn, setIsLogIn }}> */}

            <SiteRouter />
            {/* <AdminNavBar /> */}

            {/* </UserContext.Provider> */}
        </ThemeProvider>
    );
}

export default App;
