import React, { useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    useHistory,
    useLocation,
} from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import BeforeLogIn from './pages/beforeLogIn/index';
import MainPage from './pages/mainPages/index';
import { useEffect } from 'react';

// export function PrivateRoute({ children: Children, ...rest }) {
//     // const { currentUser } = useAuth();
//     const [currentUser, setCurrentUser] = useState(
//         useSelector((state) => state.global.currentUser),
//     );
//     React.useEffect(() => {
//         console.log('currentUser:', currentUser);
//     }, [currentUser]);
//     return (
//         <>
//             (
//             <Route
//                 {...rest}
//                 render={(props) => {
//                     console.log(currentUser);
//                     return currentUser ? (
//                         <Children {...props} />
//                     ) : (
//                         <Redirect to="/login" />
//                     );
//                 }}
//             ></Route>
//             )
//         </>
//     );
// }

function SiteRouter() {
    const { currentUser, route } = useSelector((state) => state.global);

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { hash, pathname, search, state } = location;

    useEffect(() => {
        if (route !== pathname + search + hash) {
            console.log('push location into redux');
            dispatch({
                type: 'UPDATE_ROUTE',
                payload: {
                    route: pathname + search + hash,
                },
            });
        } else console.log('did not push location into redux');
        return () => {};
    }, [location]);

    // useEffect(() => {
    //     if (route !== pathname + search + hash) {
    //         console.log('push redux route to history');
    //         history.push(route);
    //     } else console.log('did not push redux route to history');
    //     return () => {};
    // }, [route]);

    //
    return (
        <>
            <Switch>
                <Route path="/:name(login|in|signup|up|intro)">
                    <BeforeLogIn />
                </Route>
                <Route path="/:uri?">
                    {localStorage.getItem('userId') ? (
                        <MainPage />
                    ) : (
                        <Redirect to="/intro" />
                    )}
                </Route>
            </Switch>
        </>
    );
}
export default SiteRouter;
