import React from 'react';
import ReactDOM from 'react-dom';
import '@csstools/normalize.css';
import './index.css';
// import * as serviceWorker from './serviceWorker';

// REDUX SETUP
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import rootReducer from './redux/reducers/index';

import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';

const composeEnchancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    composeEnchancer(applyMiddleware(thunk)),
);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            {' '}
            <Router>
                <App />{' '}
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
