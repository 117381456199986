/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import './Misemon.scss';
import firebase from 'firebase';
import { Box } from '@material-ui/core';

const MisemonOpt = ({ tag_num }) => {
    const [state, setState] = useState({});
    const { tag_name, setumei, url } = state;
    useEffect(() => {
        console.log('recip grid get id : ', typeof tag_num, tag_num);
        const tagRef = firebase.database().ref('TAG_TBL');
        const recipeIdRef = tagRef.orderByChild('tag_num').equalTo(tag_num);
        recipeIdRef.once('value', (snapshot) => {
            const val = snapshot.val();
            if (val) {
                const row = Object.values(val)[0];
                setState({
                    ...state,
                    ...row,
                });
            }
        });
        return () => {
            recipeIdRef.off();
        };
    }, []);

    return (
        state && (
            <>
                <div
                    className="mise-div"
                    style={{ backgroundImage: `url(${url})` }}
                >
                    <div className="wrap">
                        <p>{tag_name}</p>
                        <p>{setumei}</p>
                    </div>
                </div>
            </>
        )
    );
};

function Misemonn({ recipeId }) {
    const [state, setState] = useState({});
    const { tag_nums } = state;
    const {
        id,
        recipe_name,
        difficulty,
        image,
        markCount,
        reviewCount,
        pro,
        time,
        money,
        setumei,
        nu_carbon,
        nu_energy,
        nu_fat,
        nu_protein,
    } = state;

    useEffect(() => {
        if (Number.isNaN(recipeId) == false) {
            console.log('recip grid get id : ', typeof recipeId, recipeId);
            const tagRef = firebase.database().ref('RECIPE_ZAIRYO_TBL');
            const recipeIdRef = tagRef
                .orderByChild('id')
                .equalTo(recipeId || 6);
            recipeIdRef.once('value', (snapshot) => {
                const val = snapshot.val();
                console.log(val);
                if (val) {
                    const rows = Object.values(val);
                    const tag_nums = Object.values(rows).map(
                        (item) => item.tag_num,
                    );
                    setState({
                        ...state,
                        tag_nums,
                    });
                }
            });
        }
        return () => {};
    }, []);

    return (
        <Box className="menu-box">
            {tag_nums &&
                tag_nums.map((tag_num) => {
                    let temp = <React.Fragment key={new Date().getTime()} />;
                    if (tag_num) {
                        temp = <MisemonOpt tag_num={tag_num} key={tag_num} />;
                    }
                    return temp;
                })}
        </Box>
    );
}

export default Misemonn;
