/* eslint-disable no-shadow */
import React from 'react';
import './Search.scss';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { spacing } from '@material-ui/system';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { sizing } from '@material-ui/system';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { StarRateTwoTone } from '@material-ui/icons';
import Sidebar from '../../components/Sidebar';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    fetchAllTags,
    fetchLastGroupNum,
} from '../../redux/actions/searchActions';
import { useEffect } from 'react';
import { useState } from 'react';
import firebase from 'firebase';
import { SearchAddButton, SearchDelButton } from './SearchButton';
import { useCallback } from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0',
        display: 'flex',
        alignItems: 'center',
        width: 800,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
        backgroundColor: 'rgb(202, 202, 202)',
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

function Search({ history }) {
    const [lastGroupNum, setlastGroupNum] = useState();
    const [searchTagArray, setSearchTagArray] = useState();
    useEffect(() => {
        const tagRef = firebase.database().ref('TAG_TBL');
        const lastGroupNumRef = tagRef.orderByChild('group_num').limitToLast(1);
        lastGroupNumRef.once('value', (snapshot) => {
            const lastGroupNumVal = snapshot.val();
            console.log('MAX_GROUP_NUM : ', lastGroupNumVal);
            setlastGroupNum(Object.values(lastGroupNumVal)[0]?.group_num);
        });
        return () => {
            lastGroupNumRef.off();
        };
    }, []);

    const classes = useStyles();
    const [toggle, setToggle] = React.useState({
        precise: false,
        save: true,
    });

    const handleToggle = (event) => {
        setToggle({ ...toggle, [event.target.name]: event.target.checked });
    };
    function initial() {
        let initialState = {
            15: { tagNum: 15, tagName: '薄力粉' },
            26: { tagNum: 26, tagName: '食パン' },
            63: { tagNum: 63, tagName: 'マカロニ・スパゲッティ' },
            191: { tagNum: 191, tagName: 'じゃがいも' },
            210: { tagNum: 210, tagName: 'でん粉' },
            441: { tagNum: 441, tagName: 'かぼちゃ' },
            543: { tagNum: 543, tagName: 'たけのこ' },
            680: { tagNum: 680, tagName: 'ほうれんそう' },
            958: { tagNum: 958, tagName: 'エリンギ' },
            1661: { tagNum: 1661, tagName: 'ベーコン' },
            1700: { tagNum: 1700, tagName: 'とりむね' },
            1744: { tagNum: 1744, tagName: '卵' },
            1762: { tagNum: 1762, tagName: '牛乳' },
            1798: { tagNum: 1798, tagName: 'チェダーチーズ' },
            1799: { tagNum: 1799, tagName: 'パルメザンチーズ' },
            1802: { tagNum: 1802, tagName: 'モッツァレラチーズ' },
            1820: { tagNum: 1820, tagName: 'オリーブ油' },
            1828: { tagNum: 1828, tagName: 'なたね油' },
            2081: { tagNum: 2081, tagName: 'ブイヨン' },
            2154: { tagNum: 2154, tagName: '白こしょう' },
            2159: { tagNum: 2159, tagName: 'おろししょうが' },
            2160: { tagNum: 2160, tagName: 'セージ' },
            2161: { tagNum: 2161, tagName: 'タイム' },
            2165: { tagNum: 2165, tagName: 'ガーリックパウダー' },
            2169: { tagNum: 2169, tagName: 'パセリ' },
        };
        if (localStorage.getItem('_willchef_search_selected')) {
            initialState = JSON.parse(
                localStorage.getItem('_willchef_search_selected'),
            );
        }
        return initialState;
    }
    const [selected, setSelected] = React.useState(initial());

    React.useEffect(() => {
        console.log(`selected : `, selected);
        if (toggle.save) {
            localStorage.setItem(
                '_willchef_search_selected',
                JSON.stringify(selected),
            );
        } else {
            localStorage.removeItem('_willchef_search_selected');
        }
        return () => console.dir('search unmount');
    }, [selected, toggle.save]);

    // Array.prototype.remove = function (val) {
    //     const index = this.indexOf(val);
    //     if (index > -1) {
    //         this.splice(index, 1);
    //     }
    //     return this;
    // };

    // function removeFromArray(val, array) {
    //     const index = array.indexOf(val);
    //     if (index > -1) {
    //         array.splice(index, 1);
    //     }
    //     return array;
    // }

    function delChekedHandler(key) {
        function removeFromObject(key1, object) {
            console.log(`key1 : ${key1}, obj`);
            const entriesArray = [];
            Object.entries(object).forEach(([key, value]) => {
                if (key !== key1) entriesArray.push([key, value]);
            });
            console.log('entriesArray : ', entriesArray);
            const fin = Object.fromEntries(entriesArray);
            console.log('fin : ', fin);
            return fin;
        }

        setSelected(removeFromObject(key, selected));
        console.log('remove resault : ', removeFromObject(key, selected));
        console.log('del key', key);
    }

    function addCheckedHandler(item) {
        setSelected({ ...selected, [item.tagNum]: item });

        console.log('selected:', selected);
        console.log('add item', item);
    }
    // WHEN TEXT SEARCH
    function textSearchHandler(e) {
        e.preventDefault();
        const searchText = e.target.querySelector('input').value;
        history.push(`/search-results/?q=${searchText}`);
    }
    function tagSearchHandler() {
        if (Object.keys(selected).length > 0) {
            let tagString = '';
            Object.values(selected).forEach((item) => {
                tagString += `${item.tagNum}+`;
            });
            tagString = tagString.slice(0, -1);
            history.push(`/search-results/?tags=${tagString}`);
        }
    }

    return (
        <div className="search">
            {/* {loading === true && (
                <div
                    style={{
                        position: 'absolute',
                        zIndex: 10,
                        width: '100%',
                        height: '100px',
                        top: '45%',
                        fontSize: '20px',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        textAlign: 'center',
                    }}
                >
                    Loading...
                </div>
            )} */}
            <Box display="block" width="800px" mx="auto">
                <h3>キーワードで検索</h3>

                <Box width="800px" display="block" mx="auto" mt={3} mb={5}>
                    <Paper
                        onSubmit={textSearchHandler}
                        component="form"
                        className={classes.root}
                    >
                        <InputBase
                            className={classes.input}
                            placeholder="キーワードを入力してください"
                            inputProps={{ 'aria-label': 'search google maps' }}
                        />

                        <Box className="keywordsearch">
                            <Button size="large" type="submit">
                                検索する
                            </Button>
                        </Box>
                    </Paper>
                </Box>

                <hr />

                <h3>タグで検索</h3>

                <Box width="800px" display="block" mx="auto" my={3}>
                    <FormGroup row>
                        <Box
                            display="inline-block"
                            width="700px"
                            className="tagsearch"
                        >
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={toggle.precise}
                                            onChange={handleToggle}
                                            name="precise"
                                        />
                                    }
                                    label="完全に一致するレシピのみ検索"
                                />
                            </Box>

                            <Box>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={toggle.save}
                                            onChange={handleToggle}
                                            name="save"
                                        />
                                    }
                                    label="絞り込み内容を記憶する"
                                />
                            </Box>
                        </Box>

                        <Box display="inline-block" width="100px" mt={2}>
                            <Button
                                variant="contained"
                                onClick={tagSearchHandler}
                            >
                                検索する
                            </Button>
                        </Box>
                    </FormGroup>
                </Box>

                <Box
                    width="700px"
                    display="block"
                    mx="auto"
                    my={3}
                    className="selecttag"
                >
                    <Box display="inline-block" width="590px">
                        <h6>選択したタグ（ 最大選択数 20 ）</h6>
                    </Box>
                    <Box display="inline-block" width="110px">
                        <Button
                            onClick={() => {
                                setSelected({});
                            }}
                            variant="contained"
                            color="secondary"
                        >
                            全てクリア
                        </Button>
                    </Box>
                    {/* delete button (selected) */}
                    {selected &&
                        Object.entries(selected).map(([num, value]) => (
                            <SearchDelButton
                                key={num}
                                num={num}
                                name={value.tagName}
                                onClick={() => delChekedHandler(num)}
                            />
                        ))}
                </Box>
                {/* ! add buttons area */}
                {Array(17)
                    .fill(1)
                    .map((v, k) => k + 1)
                    .map((group_num) => (
                        <AddButtonArea
                            key={group_num}
                            group_num={group_num}
                            selected={selected}
                            addCheckedHandler={addCheckedHandler}
                        />
                    ))}
            </Box>
        </div>
    );
}

function AddButtonArea({ group_num, addCheckedHandler, selected }) {
    const [state, setState] = useState({
        title: 'Loading',
        items: null,
    });
    useEffect(() => {
        console.log('yes', group_num);
        const tagRef = firebase.database().ref('TAG_TBL');
        const groupNumRef = tagRef.orderByChild('group_num').equalTo(group_num);
        groupNumRef.once('value', (snapshot) => {
            const groupNumVal = snapshot.val();
            // console.log(
            //     'GROUP_NUM : ',
            //     Object.values(groupNumVal).map((item) => ({
            //         tagNum: item.tag_num,
            //         tagName: item.tag_name,
            //     })),
            // );
            setState({
                ...state,
                title: Object.values(groupNumVal)[0]?.group_name,
                items: Object.values(groupNumVal).map((item) => ({
                    tagNum: item.tag_num,
                    tagName: item.tag_name,
                })),
            });
            // setlastGroupNum(Object.values(lastGroupNumVal)[0]?.group_num);
        });
        return () => {
            groupNumRef.off();
        };
    }, []);

    const addSelectedHandler = (e) => {
        if (e.target.tagName === 'BUTTON') {
            const obj = state.items.find(function (obj) {
                return obj.tagNum === Number.parseInt(e.target.value, 10);
            });
            addCheckedHandler(obj);
        }
    };

    return (
        <Box width="700px" display="block" mx="auto" my={3} className="yasai">
            <h6>#{state.title && state.title}</h6>
            <hr />
            <Box onClick={addSelectedHandler}>
                {state.items &&
                    state.items.map((item) => (
                        <SearchAddButton
                            key={item.tagNum}
                            id={item.tagNum}
                            name={item.tagName}
                            selected={selected[item.tagNum]}
                        />
                    ))}
            </Box>
        </Box>
    );
}

export default Search;
