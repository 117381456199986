import React, { PureComponent } from 'react';
import {
    ResponsiveContainer,
    ComposedChart,
    Line,
    Area,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from 'recharts';

const data = [
    {
        name: '4日(木)',
        体重: 590,
        栄養評価: 80,
        運動量: 140,
    },
    {
        name: '5日(金)',
        体重: 868,
        栄養評価: 967,
        運動量: 1506,
    },
    {
        name: '6日(土)',
        体重: 1397,
        栄養評価: 1098,
        運動量: 989,
    },
    {
        name: '7日(日)',
        体重: 1480,
        栄養評価: 1200,
        運動量: 1228,
    },
    {
        name: '8日(月)',
        体重: 1520,
        栄養評価: 1108,
        運動量: 1100,
    },
    {
        name: '9日(火)',
        体重: 1400,
        栄養評価: 680,
        運動量: 1700,
    },
];

export default class Example extends PureComponent {
    static jsfiddleUrl = '//jsfiddle.net/alidingling/9wnuL90w/';

    render() {
        return (
            <div style={{ width: '100%', height: 250 }}>
                <ResponsiveContainer>
                    <ComposedChart
                        width={200}
                        height={250}
                        data={data}
                        margin={{
                            top: 20,
                            right: 20,
                            bottom: 20,
                            left: 0,
                        }}
                    >
                        <CartesianGrid stroke="#676767" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Area
                            type="monotone"
                            dataKey="運動量"
                            fill="#FE93BC"
                            stroke="#FE93BC"
                        />
                        <Bar dataKey="栄養評価" barSize={20} fill="#8BD3DD" />
                        <Line type="monotone" dataKey="体重" stroke="#4C7788" />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        );
    }
}
