import { Button } from '@material-ui/core';
import React, { useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
// import UserContext from '../pureLogic/UserContext';
// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { auth } from '../firebase';

const GOTO = {
    SEARCH_RESULTS: '/search-results',
};
function AdminNavBarOpt({ color, to, label, ...rest }) {
    const history = useHistory();
    return (
        <Button
            color={color || 'primary'}
            onClick={() => {
                history.push(to);
            }}
            {...rest}
        >
            {label?.toLowerCase()}
        </Button>
    );
}

function AdminNavBar() {
    // const { isLogIn, setIsLogIn } = useContext(UserContext);
    const location = useLocation();
    const history = useHistory();
    // REDUX
    const dispatch = useDispatch();
    return (
        <div className="topBar">
            <p>This is Administrators&apos; Navigation Bar.</p>

            {/* クリックしたらマインページに移動 */}

            <AdminNavBarOpt
                label="log out"
                color="secondary"
                to="/login"
                onClick={() => {
                    // dispatch({ type: 'LOG_OUT' });
                    console.log('push logout');
                    console.log(location);
                    // authActions('logout')();
                    auth.signOut();
                }}
            />

            {/* クリックしたら新規登録画面に移動 */}
            <AdminNavBarOpt
                label="新規会員登録"
                color="primary"
                to="/signup"
                onClick={() => {
                    // dispatch({ type: 'LOG_OUT' });
                    history.push('/signup');
                }}
            />
            <AdminNavBarOpt
                label="ログイン"
                to="/"
                onClick={() => {
                    // dispatch({ type: 'LOG_IN' });
                    console.log(location);
                    auth.signInWithEmailAndPassword(
                        'lwj9@outlook.com',
                        'password',
                    );
                }}
            />

            <AdminNavBarOpt label="search" to="/search" />
            <AdminNavBarOpt label="searchResults" to="/search-results" />
            <AdminNavBarOpt
                label="print"
                onClick={() => {
                    window.print();
                }}
            />
        </div>
    );
}

export default AdminNavBar;
