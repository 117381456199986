/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-plusplus */
/* eslint-disable no-sequences */
/* eslint-disable no-undef */
/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
/* eslint-disable no-loop-func */
/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
import { Alarm } from '@material-ui/icons';
import React, { useEffect } from 'react';
import './Timer.css';
import Music from './clock1.mp3';

function Timer() {
    useEffect(() => {
        calcProgram();
    });

    return (
        <div className="calc">
            <div classNameName="card">
                <div className="container">
                    <div className="setters">
                        <div className="minutes-set">
                            <button className="left" data-setter="minutes-plus">
                                +
                            </button>
                            <button data-setter="minutes-minus">-</button>
                        </div>
                        <div className="seconds-set">
                            <button
                                className="left2"
                                data-setter="seconds-plus"
                            >
                                +
                            </button>
                            <button data-setter="seconds-minus">-</button>
                        </div>
                    </div>
                    <div className="circle">
                        {' '}
                        <svg
                            width="300"
                            viewBox="0 0 220 220"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g transform="translate(110,110)">
                                <circle r="100" className="e-c-base" />
                                <g transform="rotate(-90)">
                                    <circle r="100" className="e-c-progress" />
                                    <g id="e-pointer">
                                        <circle
                                            cx="100"
                                            cy="0"
                                            r="8"
                                            className="e-c-pointer"
                                        />
                                    </g>
                                </g>
                            </g>
                        </svg>{' '}
                    </div>
                    <div className="controlls">
                        <div className="display-remain-time">00:00</div>
                        <button className="play" id="pause" />
                    </div>
                </div>
            </div>
        </div>
    );
}

// circle start
function calcProgram() {
    const progressBar = document.querySelector('.e-c-progress');
    const pointer = document.getElementById('e-pointer');
    const length = Math.PI * 2 * 100;

    progressBar.style.strokeDasharray = length;

    function update(value, timePercent) {
        const offset = -length - (length * value) / timePercent;
        progressBar.style.strokeDashoffset = offset;
        pointer.style.transform = `rotate(${(360 * value) / timePercent}deg)`;
    }

    // circle ends
    const displayOutput = document.querySelector('.display-remain-time');
    const pauseBtn = document.getElementById('pause');
    const setterBtns = document.querySelectorAll('button[data-setter]');

    let intervalTimer;
    let timeLeft;
    let wholeTime = 0; // manage this to set the whole time
    let isPaused = false;
    let isStarted = false;

    update(wholeTime, wholeTime); // refreshes progress bar
    displayTimeLeft(wholeTime);

    function changeWholeTime(seconds) {
        if (wholeTime + seconds > 0) {
            wholeTime += seconds;
            update(wholeTime, wholeTime);
        }
    }

    for (let i = 0; i < setterBtns.length; i++) {
        setterBtns[i].addEventListener('click', function () {
            const param = this.dataset.setter;
            switch (param) {
                case 'minutes-plus':
                    changeWholeTime(1 * 60);
                    break;
                case 'minutes-minus':
                    changeWholeTime(-1 * 60);
                    break;
                case 'seconds-plus':
                    changeWholeTime(1);
                    break;
                case 'seconds-minus':
                    changeWholeTime(-1);
                    break;
                case 'min15':
                    wholeTime = 15 * 60;
                    update(wholeTime, wholeTime);
                    break;
            }
            displayTimeLeft(wholeTime);
        });
    }

    function timer(seconds) {
        // counts time, takes seconds
        const remainTime = Date.now() + seconds * 1000;
        displayTimeLeft(seconds);

        intervalTimer = setInterval(function () {
            timeLeft = Math.round((remainTime - Date.now()) / 1000);
            if (timeLeft < 0) {
                clearInterval(intervalTimer);
                isStarted = false;
                setterBtns.forEach(function (btn) {
                    btn.disabled = false;
                    btn.style.opacity = 1;
                });
                const audio = new Audio(Music);
                audio.play();

                displayTimeLeft(wholeTime);
                pauseBtn.classList.remove('pause');
                pauseBtn.classList.add('play');
                return;
            }
            displayTimeLeft(timeLeft);
        }, 1000);
    }
    function pauseTimer() {
        if (isStarted === false) {
            timer(wholeTime);
            isStarted = true;
            this.classList.remove('play');
            this.classList.add('pause');

            setterBtns.forEach(function (btn) {
                btn.disabled = true;
                btn.style.opacity = 0.5;
            });
        } else if (isPaused) {
            this.classList.remove('play');
            this.classList.add('pause');
            timer(timeLeft);
            isPaused = !isPaused;
        } else {
            this.classList.remove('pause');
            this.classList.add('play');
            clearInterval(intervalTimer);
            isPaused = !isPaused;
        }
    }

    function displayTimeLeft(timeLeft) {
        // displays time on the input
        const minutes = Math.floor(timeLeft / 60);
        const seconds = timeLeft % 60;
        const displayString = `${minutes < 10 ? '0' : ''}${minutes}:${
            seconds < 10 ? '0' : ''
        }${seconds}`;
        displayOutput.textContent = displayString;
        update(timeLeft, wholeTime);
    }

    pauseBtn.addEventListener('click', pauseTimer);

    (function (i, s, o, g, r, a, m) {
        i.GoogleAnalyticsObject = r;
        (i[r] =
            i[r] ||
            function () {
                (i[r].q = i[r].q || []).push(arguments);
            }),
            (i[r].l = 1 * new Date());
        (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
    })(
        window,
        document,
        'script',
        '//www.google-analytics.com/analytics.js',
        'ga',
    );

    ga('create', 'UA-46156385-1', 'cssscript.com');
    ga('send', 'pageview');
}

export default Timer;
