import React, { useEffect } from 'react';
import { Redirect, Route, useHistory, Switch } from 'react-router-dom';
import Intro from './Intro';
import SignUp from './SignUp';
import { useDispatch, useSelector } from 'react-redux';
import LogIn from './LogIn';
import { auth } from '../../firebase';

function index() {
    console.log('get in beforeLogIn');

    const history = useHistory();
    // firebase 認証状態監視ファンクション
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            if (currentUser) {
                if (window.location.pathname === '/login' || '/intro') {
                    history.push('/');
                }
            }
        });
        return () => {
            unsubscribe();
        };
    }, []);
    return (
        <>
            <Switch>
                <Route
                    exact
                    path="/(signup|up)/:step?"
                    render={(props) => <SignUp {...props} />}
                />
                <Route
                    exact
                    path="/(login|in)"
                    render={(props) => <LogIn {...props} />}
                />
                <Route exact path="/intro">
                    <Intro />
                </Route>
            </Switch>
        </>
    );
}

export default index;
