import React from 'react';
import './MyPage.css';
import Review from '../../components/Review';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { sizing } from '@material-ui/system';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Sidebar from '../../components/Sidebar';
import authActions from '../../redux/actions/authActions';

function MyPage({ history }) {
    return (
        <div className="mypage">
            <Box className="wrap1">
                <Box width="23%" display="inline-block" mx="10px">
                    <Button
                        onClick={() => {
                            history.push('/misc/user-info-change');
                        }}
                        variant="contained"
                        color="primary"
                        fullWidth="true"
                        size="large"
                    >
                        ユーザー情報変更
                    </Button>
                </Box>

                <Box width="23%" display="inline-block" mx="10px">
                    <Button
                        onClick={() => {
                            history.push('/misc/fitbit-setting');
                        }}
                        variant="contained"
                        color="primary"
                        fullWidth="true"
                        size="large"
                    >
                        fitbit連携設定
                    </Button>
                </Box>

                <Box width="23%" display="inline-block" mx="10px">
                    <Button
                        onClick={() => {
                            history.push('/misc/pay-info-change');
                        }}
                        variant="contained"
                        color="primary"
                        fullWidth="true"
                        size="large"
                    >
                        お支払情報変更
                    </Button>
                </Box>

                <Box width="23%" display="inline-block" mx="10px">
                    <Button
                        onClick={() => {
                            authActions('logout')();
                        }}
                        variant="contained"
                        color="primary"
                        fullWidth="true"
                        size="large"
                    >
                        ログアウトする
                    </Button>
                </Box>
            </Box>
        </div>
    );
}

export default MyPage;
