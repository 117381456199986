import { auth } from '../../firebase';

function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
}

function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
}

function logout() {
    return auth.signOut();
}

function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
}

function updateEmail(currentUser, email) {
    return currentUser.updateEmail(email);
}

function updatePassword(currentUser, password) {
    return currentUser.updatePassword(password);
}
const funcs = {
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
};

const authActions = (text, ...args) => async (dispatch) => {
    // FETCH ;
    try {
        await funcs[text](...args);
    } catch (err) {
        alert(err.message);
    }
};

export default authActions;
