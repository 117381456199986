import React from 'react';
import { Route } from 'react-router-dom';
import PrintPage from './PrintPage';
import UserInfoChange from './UserInfoChange';
import FitbitSetting from './FitbitSetting';
import PayInfoChange from './PayInfoChange';

function index(miscProps) {
    console.log('get in misc/index.jsx');
    return (
        <>
            <Route
                exact
                path="/misc/print"
                render={(props) => <PrintPage {...props} {...miscProps} />}
            />
            <Route
                exact
                path="/misc/user-info-change"
                render={(props) => <UserInfoChange {...props} {...miscProps} />}
            />
            <Route
                exact
                path="/misc/fitbit-setting"
                render={(props) => <FitbitSetting {...props} {...miscProps} />}
            />
            <Route
                exact
                path="/misc/pay-info-change"
                render={(props) => <PayInfoChange {...props} {...miscProps} />}
            />
        </>
    );
}

export default index;
