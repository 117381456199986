/* eslint-disable import/order */
/* eslint-disable import/no-duplicates */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import './Memo2.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { spacing } from '@material-ui/system';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { sizing } from '@material-ui/system';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

function debounce(func, wait, immediate) {
    let timeout;
    return function (...args) {
        const context = this;
        const later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

// debounce 連続に呼ばれた時、一定時間内に呼ばれていない時に実行
// const waterFallDebounce = debounce(wF, 3, true)

function Memo() {
    const divRef = useRef();

    const initialMemo = `お好みで食べやすく切った糸こんにゃくを一緒に煮ても美味しいです。
                        サヤインゲンやサヤエンドウなどを塩ゆでして、盛り付けの際に上に乗せると、色合いが華やかになりますよ。
                        冷めるときに味が染み込みますので、一度冷ましてから食べる前に温めてください。`;

    useEffect(() => {
        const savedMemo = localStorage.getItem('_willchef_recipe_memo');
        if (savedMemo) {
            divRef.current.innerText = savedMemo;
        }
        return () => {};
    }, []);

    function handleChange(e) {
        console.log('memo', typeof e.target);
        console.dir(e.target);
        localStorage.setItem('_willchef_recipe_memo', e.target.data);
    }

    useEffect(() => {
        const unsub = divRef.current.addEventListener(
            'DOMCharacterDataModified',
            handleChange,
            false,
        );
        return () => {
            divRef.current?.removeEventListener(
                'DOMCharacterDataModified',
                handleChange,
                false,
            );
        };
    }, [divRef]);

    return (
        <div className="memo">
            <div className="paper">
                <div className="lines">
                    {/* <h3>ページ１</h3> */}
                    <div
                        className="text"
                        contentEditable
                        spellCheck="false"
                        ref={divRef}
                    >
                        お好みで食べやすく切った糸こんにゃくを一緒に煮ても美味しいです。
                        サヤインゲンやサヤエンドウなどを塩ゆでして、盛り付けの際に上に乗せると、色合いが華やかになりますよ。
                        冷めるときに味が染み込みますので、一度冷ましてから食べる前に温めてください。
                    </div>
                </div>
                <div className="holes hole-top" />
                <div className="holes hole-middle" />
                <div className="holes hole-bottom" />
            </div>
        </div>
    );
}

export default Memo;
