import React from 'react';
import './Sidebar.scss';
// import SidebarOption from './SidebarOption'
import myPage from '../images/icons/mypage_white.gif';
import cookBook from '../images/icons/cookbook_white.gif';
import health from '../images/icons/health_white.gif';
import search from '../images/icons/search_white.gif';
import rogo from '../images/icons/rogo.gif';

import { Box, IconButton } from '@material-ui/core';
// import React from 'react'
// import IconButton from '@material-ui/core/IconButton';

import { Button } from '@material-ui/core';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { PhotoCamera } from '@material-ui/icons';
import { useSelector } from 'react-redux';

function goCompact(width) {
    const objSidebar = document.querySelector('body');
    if (parseInt(width, 10) === 0) {
        objSidebar.classList.add('sidebar-none');
        return;
    }
    objSidebar.classList.remove('sidebar-none');
    objSidebar.classList.add('sidebar-min');
    if (width === 'compact') {
        objSidebar.classList.add('sidebar-min');
        return;
    }
    objSidebar.classList.remove('sidebar-min');
}

function SidebarOption({
    jump,
    width,
    active,
    text,
    Icon,
    backgroundColor,
    num,
}) {
    const history = useHistory();
    return (
        <Button
            onClick={() => {
                goCompact(width);
                history.push(`/${jump}`);
            }}
            className={`sidebarOption ${
                active && 'sidebarOption--active'
            }  ${num} ${backgroundColor}`}
            style={{ padding: 0, height: '100%' }}
        >
            <img src={Icon} alt="" style={{ objectFit: 'contain' }} />
        </Button>
    );
}

function Sidebar() {
    const location = useLocation();
    console.log(location.pathname);
    const route = useSelector((state) => state.global.route);
    React.useEffect(() => {
        if (route == '/') {
            document.querySelector('body').classList.remove('sidebar-min');
        } else {
            document.querySelector('body').classList.add('sidebar-min');
        }
        return () => {};
    }, [route]);
    return (
        <div className="sidebar">
            <div className="sear">
                <SidebarOption
                    Icon={search}
                    jump="search"
                    width="compact"
                    text="search"
                    num="1"
                />
            </div>

            <div className="heal">
                <SidebarOption
                    Icon={health}
                    jump="health"
                    text="health"
                    width="compact"
                    num="2"
                />
            </div>

            <div className="cookbook">
                <SidebarOption
                    Icon={cookBook}
                    jump="cookbook"
                    num="3"
                    width="compact"
                    text="cookBook"
                />
            </div>
            <div className="mypage">
                <SidebarOption
                    Icon={myPage}
                    jump="mypage"
                    width="compact"
                    num="4"
                    text="myPage"
                />
            </div>
            <div className="rogo">
                <SidebarOption Icon={rogo} jump="" num="5" text="rogo" />
            </div>
        </div>
    );
}

export default Sidebar;
