import { Box, Button } from '@material-ui/core';
import React from 'react';

export function SearchDelButton({ num, name, onClick }) {
    return (
        <Box display="inline-block" my={0.5} mx={0.5}>
            <Button
                data-num={num}
                onClick={onClick}
                variant="outlined"
                color="secondary"
            >
                × {name + num}
            </Button>
        </Box>
    );
}
export const SearchAddButton = React.memo(({ id, name, selected }) => {
    // console.log(id, name);
    return (
        <Box display="inline-block" my={0.5} mx={0.5}>
            <Button
                variant={selected ? 'contained' : 'outlined'}
                color="secondary"
                value={id}
            >
                {id + name}
            </Button>
        </Box>
    );
});
